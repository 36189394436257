import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button, Paper, Stack, styled, Typography } from '@mui/material';
import { t } from 'i18next';
import Papa from 'papaparse';

// ----------------------------------------------------------

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '100%',
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: '100%',
});

export default function ImportCSV({ header = false, onUpload }) {
  async function handleUpload(e) {
    const file = e.target.files[0];

    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const headers = result.meta.fields;
          onUpload({ header: headers, data: result.data });
        },
        encoding: 'ISO-8859-1',
        header: header,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    }
  }

  return (
    <>
      <Stack spacing={3} sx={{ borderRadius: 3, p: 3 }}>
        <Box
          component="label"
          role={undefined}
          variant="standard"
          tabIndex={-1}
          onDrop={(e) => {
            handleUpload(e);
          }}
          sx={{
            p: 3,
            height: '100%',
            border: 1,
            borderStyle: 'dashed',
            borderColor: 'grey.400',
            color: 'grey.500',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon icon="duo-icons:upload-file" width="32" />
          <Typography textAlign="center" sx={{ mt: 2 }}>
            {t('items.upload_file')} CSV
          </Typography>
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            onChange={(e) => {
              handleUpload(e);
            }}
          />
        </Box>
      </Stack>
    </>
  );
}
