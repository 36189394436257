import { useState } from 'react';

import { Button, IconButton, Menu, MenuItem } from '@mui/material';

import { Icon } from '@iconify/react/dist/iconify.js';

//----------------------------------------------------------------

export default function Dropdown({
  iconBtn,
  text,
  variant,
  color,
  startIcon,
  endIcon,
  children,
  fullWidth = false,
  sxProps,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {iconBtn ? (
        <IconButton onClick={handleClick}>
          <Icon width={18} icon={iconBtn} />
        </IconButton>
      ) : (
        <Button
          variant={variant ?? 'outlined'}
          color={color ?? 'primary'}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={startIcon ? <Icon icon={startIcon ?? ''} /> : ''}
          endIcon={<Icon icon={endIcon ?? 'mdi:menu-down'} />}
          fullWidth={fullWidth}
        >
          {text}
        </Button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ width: '100%' }}
      >
        {children}
      </Menu>
    </>
  );
}
