import { useEffect, useState } from "react";

import { redirect, useNavigate } from "react-router-dom";

import { t } from "i18next";

import secureLocalStorage from "react-secure-storage";

import api from "../../services/api";

import inovasup from "../../services/inovasup.js";

import quickAcessList from "./quickAcessList.js";

import { Icon } from "@iconify/react";

import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Fab,
	Grid2,
	IconButton,
	LinearProgress,
	Paper,
	Stack,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography,
} from "@mui/material";

import "dayjs/locale/pt-br";

import dayjs from "dayjs";

var relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

//----------------------------------------------------------------

export default function Home() {
	dayjs.locale("pt-br");

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [items, setItems] = useState([]);

	const [notifications, setNotifications] = useState([]);

	useEffect(() => {
		async function getItems() {
			setLoading(true);
			await api
				.get(`/itensPage/${sessionStorage.getItem("idConta")}/*/*/*/*/*/*/*/*/*/*/*/*/*/*/0?limit=10`, {})
				.then((response) => {
					setItems(response.data);
					setLoading(false);
				});
		}
		getItems();
	}, []);

	useEffect(() => {
		async function getNotifications() {
			inovasup.get("/feature/list/650320e8e8f68daad2362b5c?show=true").then(
				(response) => {
					setNotifications(response.data);
				},
				(error) => {
					setNotifications([]);
				}
			);
		}
		getNotifications();
	}, []);

	return (
		<>
			<Stack spacing={3}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h1">Home</Typography>
					<Stack direction="row" spacing={1}>
						{/* Botão CBA - relatório gerencial */}

						{sessionStorage.getItem("idConta") == "Mrz2ue7F2" && (
							<>
								<Button
									variant="contained"
									startIcon={<Icon icon="mage:external-link" />}
									onClick={() => {
										window.open(
											"https://app.powerbi.com/view?r=eyJrIjoiNWEyYThiNmItZWE1OC00YTVlLThmN2QtYjYzMjdkMmM4ZmQ0IiwidCI6IjhlOTQ2MjRmLTU1YzUtNGFiMi1hMDhiLTY2Mjk3NTYwODYyNSJ9"
										);
									}}
								>
									BI
								</Button>
								<Button
									variant="text"
									startIcon={<Icon icon="mage:dashboard-bar" />}
									onClick={(e) => {
										window.open(
											"https://docs.google.com/spreadsheets/d/13JHoZ1oMZy3ZphLbBxeT_eU2csf0W3PREgD__1zv8Pc/edit?gid=897486230#gid=897486230"
										);
									}}
								>
									Relatório gerencial
								</Button>
							</>
						)}
						{/* Botão TECNOCUBA - relatório gerencial */}
						{sessionStorage.getItem("idConta") == "6B5vHaQuN" && (
							<Button
								variant="text"
								startIcon={<Icon icon="mage:dashboard-bar" />}
								onClick={(e) => {
									window.open(
										"https://drive.google.com/drive/folders/1ARo20rUOt5GmnZBnOliPBosnMi8FvtN8?usp=drive_link"
									);
								}}
							>
								Relatório gerencial
							</Button>
						)}
						<Button
							startIcon={<Icon icon="akar-icons:link-out" />}
							variant="contained"
							onClick={(e) => {
								window.open(
									"https://impressao.inovacode.app.br/auth/" +
										sessionStorage.getItem("idConta") +
										sessionStorage.getItem("idUser")
								);
							}}
						>
							{t("common.printing")}
						</Button>
					</Stack>
				</Stack>
				<Stack justifyContent="center" alignItems="center" spacing={-3}>
					<Stack
						justifyContent="center"
						sx={{ backgroundColor: "#6189f6", color: "white", p: 3, borderRadius: 3, height: "20vh", width: "100%" }}
					>
						<Typography variant="subtitle" color="white" sx={{ textTransform: "capitalize" }}>
							{new Date().toLocaleString("default", { weekday: "long", month: "long", day: "numeric" })}
						</Typography>
						<Typography variant="h1" color="white">
							{t("home.welcome")}
							{sessionStorage.getItem("username") ? ", " + sessionStorage.getItem("username") : ""}!
						</Typography>
					</Stack>
					<Stack sx={{ width: { xs: "95%", md: "80%" }, p: 2, borderRadius: 3 }} component={Paper}>
						<Typography variant="h5">{t("home.quick_acess")}</Typography>
						<Tabs
							variant="scrollable"
							scrollButtons
							allowScrollButtonsMobile
							aria-label="scrollable force tabs example"
						>
							{quickAcessList.map((menu) => (
								<>
									{menu.show ? (
										<Tab
											label={t(`menu.${menu.title}`)}
											icon={
												<IconButton sx={{ backgroundColor: "grey.100", color: "primary.main" }}>
													<Icon icon={menu.icon} />
												</IconButton>
											}
											sx={{
												backgroundColor: "common.white",
												m: 1,
												borderRadius: 2,
												"&:hover": {
													boxShadow: 1,
												},
											}}
											onClick={(e) => {
												navigate(menu.path);
											}}
										/>
									) : (
										""
									)}
								</>
							))}
						</Tabs>
					</Stack>
				</Stack>

				<Grid2 container spacing={3} flexWrap={{ xs: "wrap-reverse", md: "nowrap" }}>
					<Grid2 size={{ xs: 12, md: "grow" }} component={Paper}>
						<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ m: 1, mx: 2 }}>
							<Typography variant="h3">{t("common.items")}</Typography>
							<IconButton
								onClick={(e) => {
									navigate("/items");
								}}
							>
								<Icon icon="radix-icons:external-link" width={18} />
							</IconButton>
						</Stack>

						<TableContainer sx={{ mb: 2 }}>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell sx={{ color: "grey.500" }}>
											<Icon icon="material-symbols:tag-rounded" width="1.3em" />
										</TableCell>
										<TableCell>Item</TableCell>
										<TableCell>{t("common.last_read")}</TableCell>
										<TableCell>{t("common.created_at")}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{items.slice(0, items.length - 1).map((item, index) => {
										return (
											<TableRow
												key={index}
												hover
												sx={{
													"& .MuiTableCell-root": {
														height: "8px",
													},
												}}
											>
												<TableCell>{index + 1}</TableCell>
												<TableCell>
													<Typography component="p">{item.id_categoria ? item.id_categoria.descricao : ""}</Typography>
													<Typography variant="subtitle">{item.tag}</Typography>
												</TableCell>
												<TableCell>
													<Typography>
														{item?.dt_retorno || item?.id_registro?.dt_registro
															? dayjs(item?.dt_retorno || item?.id_registro?.dt_registro).format("DD MMMM, YYYY")
															: ""}
													</Typography>
													<Typography>
														{item?.dt_retorno || item?.id_registro?.dt_registro
															? new Date(item?.dt_retorno || item?.id_registro?.dt_registro).toLocaleTimeString(
																	"pt-BR",
																	{ hour: "2-digit", minute: "2-digit", hour12: false }
																)
															: ""}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography>
														{item?.dt_alteracao ? dayjs(item.dt_alteracao).format("DD MMMM, YYYY") : ""}
													</Typography>
													<Typography>
														{item?.dt_alteracao
															? new Date(item.dt_alteracao).toLocaleTimeString("pt-BR", {
																	hour: "2-digit",
																	minute: "2-digit",
																	hour12: false,
																})
															: ""}
													</Typography>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>

						{loading && <LinearProgress />}
					</Grid2>

					<Grid2 size={{ xs: 12, md: 4 }}>
						<Typography variant="h3" sx={{ mb: 1 }}>
							{t("home.notification")}
						</Typography>

						<Stack spacing={2}>
							{notifications.map((notification) => (
								<Grid2
									container
									spacing={1}
									sx={{
										p: 2,
										borderRadius: 2,
										boxShadow: 1,
										backgroundColor: "common.white",
									}}
								>
									<Grid2 size={1}>
										<Icon icon="cryptocurrency-color:chat" width="1.6em" />
									</Grid2>
									<Grid2 size="grow">
										<Typography variant="subtitle2">{notification.subject}</Typography>
										<Typography variant="subtitle" component="p">
											{notification.message}
										</Typography>
										<Typography variant="subtitle">
											{dayjs(notification.updatedAt).locale("pt-br").fromNow()}
										</Typography>
									</Grid2>
								</Grid2>
							))}
						</Stack>
					</Grid2>
				</Grid2>
			</Stack>

			<Fab
				size="small"
				sx={{
					position: "fixed",
					right: 20,
					bottom: 20,
					boxShadow: 3,
					zIndex: 1000,
				}}
				onClick={(e) => {
					window.location.href = "https://wa.me/5511942316933";
				}}
			>
				<Icon icon="logos:whatsapp-icon" width="100" />
			</Fab>
		</>
	);
}
