import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const libraries = ["places"];

export default function Maps({ latitude, longitude, width, height, zoom }) {
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_API_G_KEY,
		libraries,
	});

	const mapContainerStyle = {
		width: width || "100%",
		height: height || "600px",
	};

	if (loadError) return <p>Erro ao carregar o mapa</p>;
	if (!isLoaded) return <p>Carregando mapa...</p>;

	return (
		<GoogleMap
			className="mapStyles"
			mapContainerStyle={mapContainerStyle}
			zoom={zoom || 18}
			center={{ lat: Number(latitude), lng: Number(longitude) }}
		>
			<Marker
				position={{
					lat: Number(latitude),
					lng: Number(longitude),
				}}
				icon={{
					url: "/iconMap.png",
					scaledSize: new window.google.maps.Size(32, 30), // Apenas executado se isLoaded for true
				}}
			/>
		</GoogleMap>
	);
}
