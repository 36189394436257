import { useCallback, useEffect, useRef, useState } from "react";

import Menu from "../../../../../components/Menu";

import api from "../../../../../services/api";

import { Button, Container, Modal } from "react-bootstrap";

import { t } from "i18next";

import { useNavigate } from "react-router-dom";

import {
	Menu as MuiMenu,
	Backdrop,
	MenuItem,
	Alert,
	AlertTitle,
	Box,
	Checkbox,
	Button as MuiButton,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	LinearProgress,
	Paper,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	circularProgressClasses,
	Chip,
	styled,
	tooltipClasses,
} from "@mui/material";

import { useParams } from "react-router-dom";

import { IoIosSearch } from "react-icons/io";

import { amber, blue, deepPurple, green, grey, lightBlue, red, teal, yellow } from "@mui/material/colors";

import { MdBarcodeReader } from "react-icons/md";

import secureLocalStorage from "react-secure-storage";

import { HiHashtag } from "react-icons/hi";

import useWebSocket, { ReadyState } from "react-use-websocket";

import { Icon } from "@iconify/react";

import Notification from "../../../../../components/Notification/Notification";

import { CSVLink } from "react-csv";

import { Parser } from "@json2csv/plainjs";

// ----------------------------------------------------------------

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
	({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	})
);

export default function MonitoringInventory() {
	let { id } = useParams();

	const readers = secureLocalStorage.getItem(id).readers || [];

	const { no_filter } = secureLocalStorage.getItem(id);

	const history = useNavigate();

	const [saving, setSaving] = useState(false);

	const [loading, setLoading] = useState(false);

	const [items, setItems] = useState([]); // carrega todos os items cadastrados

	const [tags, setTags] = useState([]); // lista as tags lidas

	const [percentage, setPercentage] = useState(0); // porcentagem de carregamento

	const [date, setDate] = useState();

	const [count, setCount] = useState([]);

	const [config, setConfig] = useState([]);

	const [configCategoria, setConfigIdCategoria] = useState(secureLocalStorage.getItem(id)?.categoria);

	const [configMarca, setConfigMarca] = useState(secureLocalStorage.getItem(id)?.marca);

	const [configModelo, setConfigModelo] = useState(secureLocalStorage.getItem(id)?.modelo);

	const [configNivel, setConfigNivel] = useState();

	const [configTag, setConfigTag] = useState();

	const [wsOnline, setWsOnline] = useState("Conectando");

	const [conectedReader, setConectedReader] = useState("Desconectado");

	const [readerConnected, setReaderConnected] = useState([]);

	const [alertMessage, setAlertMessage] = useState();

	const [triggerSound, setTriggerSound] = useState(null);

	const [showAusente, setShowAusente] = useState(false);

	const [csv, setCSV] = useState([]);

	const [copyData, setCopyData] = useState([]);

	useEffect(() => {
		getConfig();
		getItems();
	}, []);

	function getConfig() {
		const cacheConfig = secureLocalStorage.getItem(id);
		setConfig(cacheConfig);
		if (!cacheConfig) {
			history("/collections/create");
		} else {
			const dt_formated =
				new Date(cacheConfig?.dt_inicio).toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" }) +
				" • " +
				new Date(cacheConfig?.dt_inicio).toLocaleString("default", { month: "long", day: "numeric", year: "numeric" });
			setDate(dt_formated);
			setTags(cacheConfig.tags || []);
			setConfigTag(cacheConfig.tag);
			setCount(cacheConfig?.count || []);
			if (cacheConfig.nivel_1) {
				const { nivel_4, nivel_3, nivel_2, nivel_1 } = cacheConfig;
				const nivel = nivel_4 ? 4 : nivel_3 ? 3 : nivel_2 ? 2 : 1;
				const _id = nivel_4?._id || nivel_3?._id || nivel_2?._id || nivel_1?._id;
				setConfigNivel({ nivel, _id });
			}
		}
	}

	async function getItems() {
		setLoading(true);

		await api
			.get(`/item/${sessionStorage.getItem("idConta")}/*/*/*/*/*/*/*/*/*/*/*/*/*`, {
				onDownloadProgress: (progressEvent) => {
					let totalBytes = 0;
					if (progressEvent.lengthComputable) {
						totalBytes = progressEvent.total;
					}
					const transferredBytes = progressEvent.loaded;
					const percentageValue = (transferredBytes / totalBytes) * 100;
					setPercentage(Math.round(percentageValue));
				},
			})
			.then((response) => {
				let itemsList = {};
				let filteredItems = [];
				const cacheConfig = secureLocalStorage.getItem(id);
				response.data.map((item) => {
					itemsList[item.tag] = item;
					const matchItem = getStatus(item);
					if (matchItem) {
						const formatedItem = formatItem(item, "Ausente");
						filteredItems.push({ ...formatedItem, count: 0, status: "Ausente" });
					}
				});
				setItems(itemsList);
				if (!secureLocalStorage.getItem(id)?.count) {
					setTags(filteredItems);
					const countList = { ...count };
					countList.Ausente = filteredItems.length;
					setCount(countList);
				}
				setLoading(false);
			})
			.finally
			//setLoading(false)
			();
	}

	function dtFormat(dt_props) {
		let dt = new Date(dt_props);
		let dt_leitura =
			dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2);
		dt_leitura +=
			" " +
			("0" + dt.getHours()).slice(-2) +
			":" +
			("0" + dt.getMinutes()).slice(-2) +
			":" +
			("0" + dt.getSeconds()).slice(-2);
		return dt_leitura;
	}

	function getStatus(item) {
		if (configTag && configTag !== item.ean) {
			return false;
		}

		if (configCategoria && configCategoria !== item.id_categoria?._id) {
			return false;
		}

		if (configMarca && configMarca !== item?.id_marca) {
			return false;
		}

		if (configModelo && configModelo !== item?.id_modelo) {
			return false;
		}

		if (secureLocalStorage.getItem(id)?.nivel_1) {
			const { nivel_4, nivel_3, nivel_2, nivel_1 } = secureLocalStorage.getItem(id);
			const index = nivel_4 ? 4 : nivel_3 ? 3 : nivel_2 ? 2 : nivel_1 ? 1 : null;
			const _id = nivel_4?._id || nivel_3?._id || nivel_2?._id || nivel_1?._id;
			const nivel = index ? { index, _id } : null;
			if (nivel && nivel._id !== item[`id_nivel_loc_${nivel?.index}`]?._id) {
				return false;
			}
		}

		return true;
	}

	function formatItem(item, status) {
		const updateItem = {
			id_conta: sessionStorage.getItem("idConta"),
			id_registro: "",
			id_registro_tag: "0",
			id_registro_inventario: "0",
			id_registro_coleta: "0",

			id_item: item?._id || null,
			id_categoria: item?.id_categoria?._id || "",
			ean: item?.id_categoria?.ean,
			descricao: item?.id_categoria?.descricao || "SEM DESCRICAO",
			tag: item?.tag,
			rssi: "",

			inf_compl_1: item?.inf_compl_1,
			inf_compl_2: item?.inf_compl_2,
			inf_compl_3: item?.inf_compl_3,
			inf_compl_4: item?.inf_compl_4,
			inf_compl_5: item?.inf_compl_5,

			id_nivel_loc_1: item?.id_nivel_loc_1?._id || "",
			id_nivel_loc_2: item?.id_nivel_loc_2?._id || "",
			id_nivel_loc_3: item?.id_nivel_loc_3?._id || "",
			id_nivel_loc_4: item?.id_nivel_loc_4?._id || "",
			nivel_loc_1: item?.nivel_loc_1 ? item?.nivel_loc_1?.descricao : "",
			nivel_loc_2: item?.nivel_loc_2 ? item?.nivel_loc_2?.descricao : "",
			nivel_loc_3: item?.nivel_loc_3 ? item?.nivel_loc_3?.descricao : "",
			nivel_loc_4: item?.nivel_loc_4 ? item?.nivel_loc_4?.descricao : "",

			encontrado: "0",
			auditado: "0",
			excedente: "0",
			incorporado: "0",

			foto: "",
			dt_leitura: dtFormat(new Date()),
			observacao: "",

			latitude: item?.latitude,
			longitude: item?.longitude,
		};
		updateItem[status.toLowerCase()] = 1;

		return updateItem;
	}

	function handleReadTag(tagToHandle, tagList) {
		let newTag = {};

		const readTagIndex = tagList.findIndex((tag) => tag.tag === tagToHandle);

		if (readTagIndex === -1) {
			const existingTag = items[tagToHandle];

			if (existingTag) {
				newTag = {
					...formatItem(existingTag, "Excedente"),
					status: "Outro Ambiente",
					count: (tagList[readTagIndex]?.count || 0) + 1,
				};
			} else {
				newTag = {
					...formatItem({ tag: tagToHandle }, "Excedente"),
					status: "Excedente",
					count: (tagList[readTagIndex]?.count || 0) + 1,
				};
			}
		} else {
			//Se a tag lida já foi lida anteriormente:
			newTag = tagList[readTagIndex];
			newTag.count = (tagList[readTagIndex]?.count || 0) + 1;
			if (newTag.status === "Ausente") {
				newTag.ausente = "0";
				newTag.encontrado = 1;
				newTag.status = "Encontrado";
			}
		}

		if (readTagIndex !== -1) {
			tagList[readTagIndex] = newTag;
		} else {
			tagList.unshift(newTag);
		}

		return tagList;
	}

	function handleUpdateCount(tagArray, readTotal) {
		let tagList = tagArray || [...tags];

		let countTotal = 0;

		let updatedCount = {};

		tagList.forEach((tag) => {
			updatedCount[tag.status] = (updatedCount[tag?.status] || 0) + 1;
			if (tag.status != "Ausente") {
				countTotal++;
			}
		});

		updatedCount.total = countTotal;

		updatedCount.read = readTotal;

		setCount(updatedCount);

		if (config.quant_esperada === updatedCount.total) {
			setTriggerSound({
				type: "success",
			});
			setAlertMessage({
				severity: "success",
				title: "Tags lidas com sucesso",
				text: "Quantidade esperada de tags lidas atingida.",
			});
		}
	}

	window.addEventListener("beforeunload", function (event) {
		// Chame sua função aqui
		updateCache();
	});

	function updateCache() {
		const updateCache = config;
		updateCache.tags = tags;
		updateCache.count = count;
		secureLocalStorage.setItem(id, updateCache);
	}

	useEffect(() => {
		if (wsOnline === "Servidor Offline") {
			setAlertMessage({
				severity: "error",
				title: "Conexão com o servidor perdida",
				text: "Houve um erro ao conectar com o servidor, reinicie a página ou tente novamente mais tarde.",
			});
		} else if (wsOnline === "Leitor desconectado") {
			setAlertMessage({
				severity: "error",
				title: "Conexão com o leitor perdida",
				text: "Houve um erro ao conectar com o leitor, se o erro persistir entre em contato com o suporte.",
			});
		} else {
			setAlertMessage();
		}
	}, [wsOnline]);

	// Socket
	const getSocketUrl = useCallback(() => {
		const readerQuery = secureLocalStorage.getItem(id).multiple ? "@" : readers[0]?.serial;
		return new Promise((resolve) => {
			resolve(`wss://ws-homolog.inovacode.app.br/${readerQuery}`);
		});
	}, []);

	const socket = useWebSocket(getSocketUrl, {
		heartbeat: {
			message: "ping",
			returnMessage: "pong",
			timeout: 60000,
			interval: 2500,
		},

		onOpen: (evt) => {
			setAlertMessage(null);
			socket.sendJsonMessage("I");
			socket.sendJsonMessage("ST");
			setWsOnline("Online");
		},

		onMessage: async (evt) => {
			const readerFound = readers.find((reader) => evt.data.includes(reader.serial));

			if (evt.data.includes("INV") && readerFound) {
				let message = evt.data
					.replace("INV|", "")
					.replace(/^[^\;]+\;/, "")
					.split("|");

				let tagArray = [...tags];

				let count = 0;

				message.forEach((tag) => {
					if (tag.length > 0) {
						let formatTag = tag.split(";");
						tagArray = handleReadTag(formatTag[1], tagArray);
						count++;
					}
				});

				handleUpdateCount(tagArray, count);

				setTags(tagArray);

				setReaderConnected((prevReaders) => [...prevReaders, readerFound?.serial]);
			}

			if (evt.data.includes("Desconectou")) {
				setConectedReader("Desconectado");
			}

			if (evt.data.includes("INF") || evt.data.includes("Conectou")) {
				setConectedReader("Conectado");
			}
		},

		onClose: (evt) => {
			setWsOnline("Offline");
			setConectedReader("Desconectado");
		},

		shouldReconnect: () => {
			return true;
		},
		retryOnError: () => {
			return true;
		},
		reconnectInterval: 0,
	});

	function handleMovingTags(movedTag, status, tagIndex) {
		const newStatus =
			status === "Incorporado"
				? "Outro Ambiente"
				: status === "Outro Ambiente"
					? "Incorporado"
					: status === "Ausente"
						? "Auditado"
						: status === "Auditado"
							? "Ausente"
							: "";

		const updatedList = [...tags];

		const index = showAusente ? tagIndex : updatedList.findIndex((tag) => tag.tag === movedTag.tag);

		const updateTag = updatedList[index];

		updateTag.encontrado = "0";
		updateTag.auditado = "0";
		updateTag.excedente = "0";
		updateTag.incorporado = "0";
		updateTag.ausente = "0";

		if (newStatus === "Incorporado") {
			updateTag.incorporado = 1;
			updateTag.encontrado = 1;
		}

		if (newStatus === "Outro Ambiente") {
			updateTag.excedente = 1;
		}

		if (newStatus === "Auditado") {
			updateTag.auditado = 1;
		}

		if (newStatus === "Ausente") {
			updateTag.ausente = 1;
		}

		updateTag.status = newStatus;

		updatedList[index] = updateTag;

		setTags(updatedList);

		handleUpdateCount();
	}

	function CustomChip({ status, tag, index }) {
		const style = {
			borderRadius: 100,
			fontWeight: 400,
			textTransform: "none",
		};

		if (status === "Encontrado") {
			style.backgroundColor = green[200];
			style.color = green[700];
		}

		if (status === "Ausente") {
			style.backgroundColor = red[200];
			style.color = red[700];
		}

		if (status === "Auditado") {
			style.backgroundColor = teal[200];
			style.color = teal[700];
		}

		if (status === "Incorporado") {
			style.backgroundColor = blue[200];
			style.color = blue[700];
		}

		if (status === "Outro Ambiente") {
			style.backgroundColor = amber[200];
			style.color = amber[700];
		}

		if (status === "Excedente") {
			style.backgroundColor = deepPurple[200];
			style.color = deepPurple[700];
		}

		const [anchorEl, setAnchorEl] = useState(null);
		const open = Boolean(anchorEl);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		return (
			<>
				<MuiButton
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={
						status === "Incorporado" || status === "Outro Ambiente" || status === "Auditado" || status === "Ausente"
							? handleClick
							: undefined
					}
					sx={style}
				>
					{status}
					{status === "Incorporado" || status === "Outro Ambiente" || status === "Auditado" || status === "Ausente" ? (
						<Icon icon="bxs:down-arrow" className="ms-2" />
					) : (
						""
					)}
				</MuiButton>
				<MuiMenu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					<MenuItem>{status}</MenuItem>
					<MenuItem
						onClick={(e) => {
							handleMovingTags(tag, status, index);
						}}
					>
						{status === "Incorporado"
							? "Outro Ambiente"
							: status === "Outro Ambiente"
								? "Incorporado"
								: status === "Ausente"
									? "Auditado"
									: status === "Auditado"
										? "Ausente"
										: ""}
					</MenuItem>
				</MuiMenu>
			</>
		);
	}

	function handleCancel() {
		socket.sendJsonMessage("F");
		secureLocalStorage.clear();
		history("/collections/inventories");
	}

	function getTimer() {
		const dt_time = new Date() - config.dt_inicio;
		const horas = Math.floor(dt_time / 3600000);
		const minutos = Math.floor((dt_time % 3600000) / 60000);
		const segundos = Math.floor((dt_time % 60000) / 1000);

		// Formata a string de tempo
		const tempoFormatado = `${horas.toString().padStart(2, "0")}:${minutos.toString().padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
		return tempoFormatado;
	}

	function handleSave() {
		socket.sendJsonMessage("F");
		setLoading(true);
		let latitude;
		let longitude;
		navigator.geolocation.getCurrentPosition(
			(position) => {
				latitude = position.coords.latitude;
				longitude = position.coords.longitude;
			},
			(error) => {
				console.error("Erro ao obter localização:", error);
			}
		);

		let reg = [
			{
				id_registro: id,
				id_registro_vinculado: "",
				id_conta: sessionStorage.getItem("idConta"),
				id_usuario: sessionStorage.getItem("idUser"),
				id_leitor: config?.reader?._id,
				modo: "il",
				filtro_itens: "",
				id_tipo_registro: "",
				identificador: id,
				dt_registro: dtFormat(config.dt_inicio),
				id_categoria: configCategoria || "",
				id_parceiro: null,
				id_nivel_loc_1: config?.nivel_1?._id || "",
				id_nivel_loc_2: config?.nivel_2?._id || "",
				id_nivel_loc_3: config?.nivel_3?._id || "",
				id_nivel_loc_4: config?.nivel_4?._id || "",
				nivel_loc_1: config?.nivel_1?.descricao || "",
				nivel_loc_2: config?.nivel_2?.descricao || "",
				nivel_loc_3: config?.nivel_3?.descricao || "",
				nivel_loc_4: config?.nivel_4?.descricao || "",
				ean: "",
				quantidade: count?.total,
				encontrado: count?.Encontrado,
				excedente: count?.Excedente,
				qtd_auditado: count?.Auditado,
				def_id_categoria: configCategoria || "*",
				def_id_parceiro: null,
				def_id_item: "",
				def_id_nivel_loc_1: config?.nivel_1?._id || "*",
				def_id_nivel_loc_2: config?.nivel_2?._id || "*",
				def_id_nivel_loc_3: config?.nivel_3?._id || "*",
				def_id_nivel_loc_4: config?.nivel_4?._id || "*",
				latitude: latitude,
				longitude: longitude,
				foto: "",
				status: "1",
				dt_alteracao: dtFormat(new Date()),
				observacao: "",
				tempo_inventario: getTimer(),
			},
		];

		let option = {
			headers: { "Content-Type": ["application/json"] },
		};

		api.post("/registro", reg, option).then(
			(response) => {
				handleTagPicking(response.data[0]._id);
			},
			(response) => {
				setLoading(false);
				setTriggerSound({
					type: "error",
				});
				setAlertMessage({
					severity: "error",
					title: "Erro 400",
					text: "Ocorreu um erro ao conectar com o servidor.",
				});
			}
		);
	}

	function handleTagPicking(id_registro) {
		let option = {
			headers: { "Content-Type": ["application/json"] },
		};

		let registerTag = tags.map((tag) => ({ ...tag, id_registro }));

		if (no_filter) {
			registerTag = registerTag.filter((i) => i.status !== "Ausente");
		}

		setCSV(registerTag);

		const parser = new Parser({ delimiter: ";" });
		const copyDataFormater = parser.parse(registerTag);
		setCopyData(copyDataFormater);
		api.post("/registro_tag_picking", registerTag, option).then(
			(response) => {
				secureLocalStorage.clear();
				setLoading(false);
				setSaving(true);
			},
			(response) => {
				window.alert(`Temos um problema...\n${response.data.error}`);
				setLoading(false);
				setTriggerSound({
					type: "error",
				});
				setAlertMessage({
					severity: "error",
					title: "Erro 400",
					text: "Ocorreu um erro ao conectar com o servidor.",
				});
			}
		);
	}

	function handleCopyBoard() {
		navigator.clipboard.writeText(copyData);
		setAlertMessage({
			severity: "success",
			title: "Sucesso",
			text: "Texto copiado para area de transferencia.",
		});
	}

	return (
		<>
			<Stack spacing={3} sx={{ p: 5 }}>
				<Grid container alignItems="flex-end" spacing={1} sx={{ p: 2 }}>
					<Grid item md>
						<Typography variant="h1" className="fw-bold">
							Inventário
						</Typography>
						<Typography variant="subtitle1">Iniciado em {date}</Typography>
					</Grid>
					<Grid item md container justifyContent={"flex-end"}>
						<Button
							variant="light"
							className="me-1"
							onClick={(e) => {
								handleCancel();
							}}
						>
							Cancelar
						</Button>
						<Button
							variant="danger"
							onClick={(e) => {
								handleSave();
							}}
						>
							Salvar
						</Button>
					</Grid>
				</Grid>

				<Box sx={{ p: 3, pt: 5, borderRadius: 3 }} component={Paper} className="shadow-sm">
					<Grid container justifyContent="flex-end">
						<Stack container direction="row" spacing={3} sx={{ mr: 3 }}>
							{wsOnline != "Online" ? (
								<Stack container direction="row" alignItems="center" spacing={1}>
									<Stack direction="row" alignItems="center" spacing={1}>
										<Icon
											icon={wsOnline === "Conectando" ? "eos-icons:bubble-loading" : "mingcute:wifi-fill"}
											color={wsOnline === "Online" ? green[600] : red[600]}
										/>
										<Typography>Socket {wsOnline}</Typography>
									</Stack>
								</Stack>
							) : (
								""
							)}
						</Stack>
					</Grid>
					<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
						<div>
							<Stack direction="row" alignItems="flex-end">
								<Typography variant="h1" sx={{ fontWeight: 500, fontSize: { xs: "2.8em", md: "3.1em" } }}>
									{count.total || 0}
								</Typography>
							</Stack>
							<Typography variant="subtitle1" sx={{ fontSize: "1.5em" }}>
								Total de tags
							</Typography>
							<Stack direction="col" alignItems="flex-end">
								<Typography variant="subtitle1" sx={{ fontSize: "1em", color: "grey.A400" }}>
									Tags lidas na última leitura:
								</Typography>
								<Typography variant="subtitle1" sx={{ fontWeight: 500, color: "grey.A400" }}>
									{count.read || 0}
								</Typography>
							</Stack>
						</div>
						<LightTooltip
							title={
								<>
									{readers.map((reader) => (
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											sx={{ borderBottom: 1, borderColor: "grey.200", p: 1, minWidth: 200 }}
										>
											<div>
												<Typography>{reader?.nm_leitor}</Typography>
												<Typography variant="subtitle">{reader?.serial}</Typography>
											</div>
											<Icon
												icon="pepicons-pencil:circle-filled"
												color={readerConnected.find((item) => item == reader.serial) ? green[400] : red[400]}
											/>
										</Stack>
									))}
								</>
							}
							placement="bottom-end"
						>
							<Stack
								direction="row"
								alignItems="center"
								sx={{
									border: 1,
									borderColor: "grey.300",
									borderRadius: 3,
									p: 1,
									py: 0.5,
								}}
								spacing={2}
							>
								<Typography>Leitores</Typography>
								<Icon color={grey[400]} icon="iconamoon:arrow-down-2" />
							</Stack>
						</LightTooltip>
					</Stack>
					<Grid container spacing={1} sx={{ mt: 5 }}>
						<Grid item xs>
							<Grid
								item
								container
								alignItems="center"
								className="border-end"
								sx={{
									p: 1,
									pr: 3,
								}}
							>
								<Grid item>
									<IconButton sx={{ color: green[600] }}>
										<Icon icon="mage:box-check" />
									</IconButton>
								</Grid>
								<Grid item xs>
									<Typography>Encontrada</Typography>
								</Grid>
								<Grid item>
									<Typography>{count.Encontrado || 0}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs>
							<Grid
								item
								container
								alignItems="center"
								className="border-end"
								sx={{
									p: 1,
									pr: 3,
								}}
							>
								<Grid item>
									<IconButton sx={{ color: red[400] }}>
										<Icon icon="mage:box-3d-cross" />
									</IconButton>
								</Grid>
								<Grid item xs>
									<Typography>Ausente</Typography>
								</Grid>
								<Grid item>
									<Typography>{count.Ausente || 0}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs>
							<Grid
								item
								container
								alignItems="center"
								className="border-end"
								sx={{
									p: 1,
									pr: 3,
								}}
							>
								<Grid item>
									<IconButton sx={{ color: deepPurple[300] }}>
										<Icon icon="mage:box-question-mark" />
									</IconButton>
								</Grid>
								<Grid item xs>
									<Typography>Excedente</Typography>
								</Grid>
								<Grid item>
									<Typography>{count.Excedente || 0}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs>
							<Grid
								item
								container
								alignItems="center"
								className="border-end"
								sx={{
									p: 1,
									pr: 3,
								}}
							>
								<Grid item>
									<IconButton sx={{ color: amber[400] }}>
										<Icon icon="mdi:box-search-outline" />
									</IconButton>
								</Grid>
								<Grid item xs>
									<Typography>Outro Ambiente</Typography>
								</Grid>
								<Grid item>
									<Typography>{count["Outro Ambiente"] || 0}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs>
							<Grid
								item
								container
								alignItems="center"
								className="border-end"
								sx={{
									p: 1,
									pr: 3,
								}}
							>
								<Grid item>
									<IconButton sx={{ color: teal[200] }}>
										<Icon icon="lucide:file-box" />
									</IconButton>
								</Grid>
								<Grid item xs>
									<Typography>Auditado</Typography>
								</Grid>
								<Grid item>
									<Typography>{count.Auditado || 0}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs>
							<Grid
								item
								container
								alignItems="center"
								sx={{
									p: 1,
									pr: 3,
								}}
							>
								<Grid item>
									<IconButton sx={{ color: blue[200] }}>
										<Icon icon="mage:box-3d-upload" />
									</IconButton>
								</Grid>
								<Grid item xs>
									<Typography>Incorporados</Typography>
								</Grid>
								<Grid item>
									<Typography>{count.Incorporado || 0}</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>

				<Stack sx={{ p: 3 }} spacing={2} as={Paper}>
					<Stack direction="row" justifyContent="space-between">
						<FormControl variant="standard" label="Controlled" sx={{ width: "45ch" }}>
							<Input
								placeholder="Buscar tags..."
								sx={{ py: 1 }}
								startAdornment={
									<InputAdornment position="start">
										<IoIosSearch />
									</InputAdornment>
								}
							/>
						</FormControl>
					</Stack>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									onClick={(e) => {
										setShowAusente(e.target.checked);
									}}
								/>
							}
							label="Mostrar Ausentes"
						/>
					</FormGroup>

					<TableContainer sx={{ mt: 3 }}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ color: "grey.A400" }}>#</TableCell>
									<TableCell sx={{ color: "grey.A400" }}>Descrição</TableCell>
									<TableCell sx={{ color: "grey.A400" }} align="right">
										TAG
									</TableCell>
									<TableCell sx={{ color: "grey.A400" }} align="right">
										Total
									</TableCell>
									<TableCell sx={{ color: "grey.A400" }} align="right">
										Status
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tags
									.filter((tag) => showAusente || tag.status !== "Ausente")
									.map((tag, index) => (
										<TableRow key={tag.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											{" "}
											<TableCell sx={{ width: "8px" }}>{index + 1}</TableCell>
											<TableCell component="th" scope="row" sx={{ fontWeight: "500" }}>
												{tag?.descricao}
											</TableCell>
											<TableCell align="right">{tag?.tag}</TableCell>
											<TableCell align="right">{tag?.count}</TableCell>
											<TableCell align="right">
												<CustomChip status={tag?.status} tag={tag} index={index} />
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Stack>

			{alertMessage ? (
				<Snackbar
					open={alertMessage ?? false}
					autoHideDuration={200}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				>
					<Alert
						severity={alertMessage.severity}
						variant="filled"
						sx={{ width: "500px" }}
						onClose={() => {
							setAlertMessage();
						}}
					>
						<AlertTitle>{alertMessage.title}</AlertTitle>
						{alertMessage.text}
					</Alert>
				</Snackbar>
			) : (
				""
			)}

			{triggerSound ? <Notification type={triggerSound.type} /> : ""}

			<Backdrop sx={{ color: "#fff", zIndex: 10000 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Modal
				show={saving}
				onHide={(e) => {
					setSaving(false);
				}}
				centered
			>
				<Modal.Body className="p-5">
					<Stack justifyContent="center" alignItems="center" spacing={1}>
						<Stack sx={{ color: "success.light", p: 3 }}>
							<Icon icon="icon-park-outline:check-one" width="64" />
						</Stack>
						<Typography variant="h6">Sucesso!</Typography>
						<Typography>Inventário salvo com sucesso</Typography>
					</Stack>
				</Modal.Body>
				<Modal.Footer>
					<Grid container direction="row" justifyContent="space-between" alignItems="center">
						<Button
							variant="light"
							onClick={(e) => {
								handleCopyBoard();
							}}
						>
							<Icon icon="pajamas:link" width="18" /> Copiar
						</Button>
						<Stack direction="row" spacing={1}>
							<Button
								variant="light"
								onClick={(e) => {
									history("/collections/inventories");
								}}
							>
								Fechar
							</Button>
							<CSVLink
								data={csv}
								filename={`inventario-${date}.csv`}
								className="btn btn-primary"
								target="_blank"
								separator={";"}
							>
								<Icon icon="iconoir:import" width="18" /> Baixar inventário
							</CSVLink>
						</Stack>
					</Grid>
				</Modal.Footer>
			</Modal>
		</>
	);
}
