import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	DataGrid as MUiDataGrid,
} from "@mui/x-data-grid";

import { ptBR } from "@mui/x-data-grid/locales";

import { useEffect, useState } from "react";

//--------------------------------------------------------

export default function DataGrid({
	tableData = [],
	header = [],
	sortBy,
	limitPerPage = 10,
	hiddenColumns = [],
	checkboxSelection = false,
	onClick = () => {},
	onSelect = () => {},
	showToolbar = false,
	showColumnsButton = false,
	showExportButton = false,
	selected = [],
}) {
	const [selectedRows, setSelectedRows] = useState(selected || []);

	useEffect(() => {
		onSelect(selectedRows);
	}, [selectedRows]);

	const rows = tableData.map((item, index) => ({
		id: index + 1,
		...item,
	}));

	const columnVisibilityModel = hiddenColumns.reduce((acc, item) => {
		acc[item] = false;
		return acc;
	}, {});

	function CustomToolbar() {
		return (
			<GridToolbarContainer
				sx={{ py: 3, display: showColumnsButton || showExportButton ? "flex" : "none", justifyContent: "flex-end" }}
			>
				{showColumnsButton && (
					<GridToolbarColumnsButton
						slotProps={{
							button: { variant: "outlined" },
						}}
					/>
				)}
				{showExportButton && (
					<GridToolbarExport
						printOptions={{
							hideToolbar: true,
						}}
						slotProps={{
							button: { variant: "outlined" },
						}}
					/>
				)}
			</GridToolbarContainer>
		);
	}

	return (
		<>
			{rows && (
				<MUiDataGrid
					localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
					rows={rows}
					columns={header}
					density="comfortable"
					disableRowSelectionOnClick={true}
					onRowClick={(row) => {
						onClick(row.row);
					}}
					onRowSelectionModelChange={(value) => {
						setSelectedRows(value);
					}}
					sx={{
						border: 0,
						"& .MuiDataGrid-cell": {
							alignItems: "center", // Centraliza horizontalmente
							display: "flex", // Necessário para centralizar corretamente
							padding: 2, // Adiciona padding às células
						},
						"& .MuiDataGrid-columnHeader": {
							alignItems: "center",
						},
						"& .MuiDataGrid-columnHeaderTitle": {
							fontWeight: "bold", // Opcional, para destaque dos títulos
							color: "grey.600",
						},
					}}
					getRowHeight={() => "auto"}
					initialState={{
						sorting: {
							sortModel: [{ field: sortBy, sort: "asc" }],
						},
						pagination: {
							paginationModel: {
								pageSize: limitPerPage,
							},
						},
						columns: {
							columnVisibilityModel,
						},
					}}
					pageSizeOptions={[5, 50, 100, 500]}
					checkboxSelection={checkboxSelection}
					slots={{ toolbar: CustomToolbar }}
					slotProps={{
						columnsManagement: {
							toggleAllMode: "filteredOnly",
						},
						loadingOverlay: {
							variant: "skeleton",
							noRowsVariant: "skeleton",
						},
					}}
				/>
			)}
		</>
	);
}
