import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import {
	Chip,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";

export default function RegistersList({ rows = [], header = [], filters }) {
	const navigate = useNavigate();
	const [order, setOrder] = useState("asc"); // Direção da ordenação
	const [orderBy, setOrderBy] = useState(""); // Coluna sendo ordenada

	const handleSort = (property) => {
		const isAscending = orderBy === property && order === "asc";
		setOrder(isAscending ? "desc" : "asc");
		setOrderBy(property);
	};

	// Função para ordenar as linhas
	const sortedRows = rows.sort((a, b) => {
		if (a[orderBy] < b[orderBy]) {
			return order === "asc" ? -1 : 1;
		}
		if (a[orderBy] > b[orderBy]) {
			return order === "asc" ? 1 : -1;
		}
		return 0;
	});

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						{header.map((h) => (
							<TableCell key={h} sx={{ fontFamily: "Poppins", textWrap: "nowrap" }}>
								<TableSortLabel
									active={orderBy === h}
									direction={orderBy === h ? order : "asc"}
									onClick={() => handleSort(h)}
								>
									{h.replace(/_/g, " ")}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{sortedRows.map((row, index) => (
						<TableRow
							key={index}
							onClick={() => {
								navigate("/registers/" + row.id_item, { state: { redirect: "/registers", filters: filters } });
							}}
						>
							{header.map((h) => (
								<TableCell key={h} component="th" scope="row" sx={{ minWidth: 300 }}>
									{h == "item" ? (
										<>
											<Typography variant="subtitle2">{row[h].descricao}</Typography>
											<Typography variant="subtitle">{row[h].tag}</Typography>
										</>
									) : (
										<>{row[h]}</>
									)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
