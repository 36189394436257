import { useEffect, useState } from "react";

import {
	Container,
	TextField,
	Button,
	Typography,
	List,
	ListItem,
	ListItemText,
	Paper,
	Stack,
	Grid2,
} from "@mui/material";

export function TaskDetail({ task, onReturn = () => {} }) {
	const [nome, setNome] = useState(task?.nome || "");

	const [funcao, setFuncao] = useState(task?.funcao || "");

	const [periodo, setPeriodo] = useState(task?.periodo || "");

	useEffect(() => {
		onReturn({ nome, funcao, periodo });
	}, [nome, funcao, periodo]);

	return (
		<>
			<Grid2 container spacing={3}>
				<Grid2 size={{ xs: 12, md: 6 }}>
					<TextField
						size="small"
						label="Nome"
						name="nome"
						value={nome}
						onChange={(e) => {
							setNome(e.target.value);
						}}
						fullWidth
					/>
				</Grid2>
				<Grid2 size={{ xs: 12, md: 6 }}>
					<TextField
						size="small"
						label="Função"
						name="funcao"
						value={funcao}
						onChange={(e) => {
							setFuncao(e.target.value);
						}}
						fullWidth
					/>
				</Grid2>
				<Grid2 size={12}>
					<TextField
						size="small"
						label="Período"
						name="periodo"
						type="number"
						value={periodo}
						onChange={(e) => {
							setPeriodo(e.target.value);
						}}
						fullWidth
					/>
				</Grid2>
			</Grid2>
		</>
	);
}
