import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import {
	Box,
	Chip,
	Divider,
	Grid2,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip,
	Typography,
} from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function RegistersGrid({ rows = [], header = [], filters }) {
	const navigate = useNavigate();

	return (
		<Grid2 container spacing={4}>
			{rows.map((row) => (
				<Grid2
					size={{ xs: 12, md: 4 }}
					onClick={() => {
						navigate("/registers/" + row.id_item, { state: { redirect: "/registers", filters: filters } });
					}}
					sx={{ cursor: "pointer" }}
				>
					<Stack as={Paper} sx={{ borderRadius: 3, borderColor: "grey.300" }}>
						<Stack sx={{ p: 3 }} spacing={1}>
							{row?.imagem ? (
								<Tooltip title="Clique na imagem para expandir">
									<Box
										component="img"
										src={row?.imagem.includes(".jpeg") ? "https://api.inovacode.app.br/" + row?.imagem : row?.imagem}
										sx={{
											borderRadius: 3,
											width: "80px",
											height: "80px",
											objectFit: "cover",
										}}
									/>
								</Tooltip>
							) : (
								<>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="center"
										as={Paper}
										sx={{
											borderRadius: 3,
											width: "80px",
											height: "80px",
											color: "grey.500",
										}}
									>
										<Icon icon="mi:image" width={24} />
									</Stack>
								</>
							)}
							<div>
								<Typography sx={{ fontWeight: 600, fontFamily: "poppins" }}>{row?.item?.descricao}</Typography>
								<Typography variant="subtitle">{row?.item?.tag}</Typography>
							</div>
							<Typography color="primary" variant="subtitle">
								{row?.status}
							</Typography>
						</Stack>
						<Divider sx={{ borderStyle: "dashed", borderColor: "grey.600" }} />
						<Stack sx={{ p: 3, color: "grey.600" }} spacing={1}>
							<Stack direction="row" spacing={1} alignItems="center">
								<Icon icon="solar:calendar-line-duotone" />
								<Typography>{row["Última atualização"]}</Typography>
							</Stack>
							<Stack direction="row" spacing={1} alignItems="center">
								<Icon icon="heroicons:user-16-solid" />
								<Typography>{row[Object.keys(row).find((i) => i.includes("nome"))]}</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Grid2>
			))}
		</Grid2>
	);
}
