import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import secureLocalStorage from "react-secure-storage";

import { grey } from "@mui/material/colors";

import {
	Stack,
	Typography,
	Button,
	Box,
	TextField,
	FormGroup,
	FormControl,
	FormLabel,
	FormControlLabel,
	Radio,
	RadioGroup,
	Paper,
	Grid2,
} from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import api from "../../services/api";

import inovasup from "../../services/inovasup";

import emailjs from "emailjs-com";

import Alert from "../../components/ui/Alert";

import { t } from "i18next";
import dayjs from "dayjs";

//-----------------------------------------------------------------

export default function NTISendData() {
	const navigate = useNavigate();

	const location = useLocation();

	const props = location?.state || {};

	const { item, foto } = props || {};

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState();

	const usuario = secureLocalStorage.getItem("transport-user");

	const [codIdentificacao, setCodIdentificacao] = useState();

	const [entregaFinal, setEntregaFinal] = useState(false);

	const [entregaEfetuada, setEntregaEfetuada] = useState(true);

	//@ Dados do item/inventario
	const [locate, setLocate] = useState(); // Coordenadas

	// @ Dados do cliente
	const [nomeRecebedor, setNomeRecebedor] = useState();

	const [docRecebedor, setDocRecebedor] = useState();

	// Bloquear
	const [bloquearSave, setBloquearSave] = useState();

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				setLocate({
					lat: position.coords.latitude,
					long: position.coords.longitude,
				});
			},
			(error) => {
				// Erro ao obter a localização
				console.error(error);
			}
		);
	}, []);

	useEffect(() => {
		if (!item || !usuario?.nome) {
			setMessage({
				type: "error",
				message: t("messages.errors.500"),
				title: t("messages.errors.error"),
				function: () => {
					navigate("/nti/qrcode");
				},
			});
		}
	}, []);

	function handleSave() {
		if (foto) {
			uploadFoto();
		} else {
			uploadItem();
		}
	}

	function uploadFoto() {
		setLoading(true);

		const option = {
			headers: { "Content-Type": ["application/json"] },
		};

		const { id_conta } = item;

		//Upload Imagem
		api.post(`/save_jpeg/${id_conta}`, [{ foto: foto }], option).then(
			(response) => {
				uploadItem(response?.data[0]?.id_foto);
			},
			(error) => {
				setLoading(false);
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			}
		);
	}

	function uploadItem(id_imagem = "") {
		//Upload Item
		setLoading(true);
		const upcommingReg = {
			id_conta: item?.id_conta,
			id_item: item?._id,
			imagem: id_imagem,
			status: entregaEfetuada
				? entregaFinal
					? "Pedido Entregue"
					: "Em trânsito"
				: "Pedido não entregue - Cliente ausente no momento da tentativa de entrega",
			nome: usuario?.nome, //entregador
			campos: {
				cód_identificacao: codIdentificacao,
				entrega_efetuada: entregaEfetuada,
				"nome_(entregador)": usuario?.nome, //entregador
				"telefone_(entregador)": usuario?.telefone, //entregador
				"nome(recebedor)_": nomeRecebedor,
				"documento_(recebedor)_": docRecebedor,
				...locate,
			},
		};

		const option = {
			headers: { "Content-Type": ["application/json"] },
		};

		api.post(`/rastreamentoItem`, [upcommingReg], option).then(
			(response) => {
				setLoading(false);
				setMessage({
					type: "success",
					message: t("messages.success_save"),
					title: t("messages.success"),
					function: () => {
						navigate("/nti/qrcode");
					},
				});
			},
			(error) => {
				setLoading(false);
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			}
		);
	}

	function uploadInovasupp() {
		const supData = [
			{
				nome: usuario?.nome,
				telefone: usuario?.telefone,
				cliente: {
					id_conta: item?.usuario?.id_conta,
					nome: item?.usuario?.nome,
					email: item?.usuario?.login,
				},
			},
		];

		inovasup.post("/delivery", supData);
	}

	function sendEmail() {
		const message = {
			nomeRecebedor: nomeRecebedor,
			data: new Date().toLocaleString("pt-BR", {
				day: "2-digit",
				hour: "2-digit",
				year: "numeric",
				minute: "2-digit",
				month: "long",
			}),
			cliente_email: item?.email,
			send_to: item?.email,
		};
		emailjs.send("service_buqiwn9", "template_6q1lkc9", message, "R0Z9hwQKUdXVUD7Rx");
	}

	return (
		<>
			<Stack spacing={3}>
				<Stack direction="row">
					<Button
						color="dark"
						startIcon={<Icon icon="iconamoon:arrow-left-2-bold" />}
						onClick={() => {
							navigate("/nti/upload-foto", { state: props });
						}}
					>
						Voltar
					</Button>
				</Stack>

				<div>
					<Typography variant="h1" textAlign="left">
						Informações
					</Typography>
					<Typography variant="subtitle">Confirme os dados antes de confirmar envio</Typography>
				</div>

				<Stack as={Paper} sx={{ borderRadius: 3 }}>
					<Stack
						spacing={2}
						sx={{
							p: 3,
							border: 0,
							borderBottom: 1,
							borderStyle: "dashed",
							borderColor: "divider",
						}}
					>
						<Typography variant="h3">Identificador</Typography>
						<Grid2 container direction="row" spacing={1} wrap={{ xs: "wrap-reverse", md: "nowrap" }}>
							{foto && (
								<Grid2 size={1}>
									<Box
										component="img"
										src={foto}
										sx={{
											borderRadius: 1,
											width: "100%",
										}}
									/>
								</Grid2>
							)}
							<Grid2 size="grow">
								<Typography>{item?.identificador}</Typography>
								<Typography variant="subtitle">{item?.tag}</Typography>
							</Grid2>
						</Grid2>
					</Stack>

					<Stack spacing={2} sx={{ p: 3 }}>
						<Typography variant="h3">Dados</Typography>
						<Stack direction="row" spacing={2}>
							<Stack spacing={2}>
								<Typography color="secondary">Nome</Typography>
								<Typography color="secondary">Telefone</Typography>
							</Stack>
							<Stack spacing={2}>
								<Typography>{usuario?.nome}</Typography>
								<Typography>{usuario?.telefone}</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Stack>

				<Stack as={Paper} sx={{ borderRadius: 3, p: 3 }} spacing={3}>
					<FormControl>
						<Typography sx={{ fontWeight: 600 }}>Cód. de Identificação</Typography>
						<TextField
							size="small"
							value={codIdentificacao}
							onChange={(e) => {
								setCodIdentificacao(e.target.value);
							}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel id="entrega-check">Cliente estava em casa para receber a encomenda?</FormLabel>
						<RadioGroup
							row
							aria-labelledby="entrega-check"
							name="row-radio-buttons-group"
							value={entregaEfetuada}
							onChange={(e) => {
								setEntregaEfetuada(!entregaEfetuada);
							}}
						>
							<FormControlLabel value={true} control={<Radio />} label="Sim" />
							<FormControlLabel value={false} control={<Radio />} label="Não" />
						</RadioGroup>
					</FormControl>
					{entregaEfetuada ? (
						<>
							<FormControl>
								<Typography>Nome</Typography>
								<TextField
									size="small"
									value={nomeRecebedor}
									onChange={(e) => {
										setNomeRecebedor(e.target.value);
									}}
								/>
							</FormControl>

							<FormControl>
								<Typography>Documento/Telefone</Typography>
								<TextField
									size="small"
									value={docRecebedor}
									onChange={(e) => {
										setDocRecebedor(e.target.value);
									}}
								/>
							</FormControl>

							<FormControl>
								<FormLabel id="entrega-final-check">Essa entrega está sendo feita ao cliente final?</FormLabel>
								<RadioGroup
									row
									aria-labelledby="entrega-final-check"
									name="row-radio-buttons-group"
									value={entregaFinal}
									onChange={(e) => {
										setEntregaFinal(e.target.value);
									}}
								>
									<FormControlLabel value={true} control={<Radio />} label="Sim" />
									<FormControlLabel value={false} control={<Radio />} label="Não" />
								</RadioGroup>
							</FormControl>
						</>
					) : (
						""
					)}
				</Stack>

				<Button
					disabled={bloquearSave}
					variant="contained"
					fullWidth
					onClick={() => {
						handleSave();
					}}
				>
					Enviar
				</Button>
			</Stack>

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={() => {
						if (message?.function) {
							message.function(); // Executa a função definida
						}
						setMessage(null); // Reseta a mensagem
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
