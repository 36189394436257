import { useEffect, useState } from 'react';

import { t } from 'i18next';

import Loading from '../../components/ui/Loading';

import api from '../../services/api';

import {
  Box,
  Button,
  Grid2,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import { Icon } from '@iconify/react/dist/iconify.js';

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import { useNavigate } from 'react-router-dom';

import { ptBR } from '@mui/x-data-grid/locales';

import CustomModal from '../../components/ui/CustomModal';

import Alert from '../../components/ui/Alert';
import Dropdown from '../../components/ui/Dropdown';

// ----------------------------------------------------------

export default function Orders() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState([]);

  const [message, setMessage] = useState();

  const [selectedOrder, setSelectedOrder] = useState();

  // Filters
  const [identificador, setIdentificador] = useState();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
    },
    {
      field: 'identificador',
      headerName: 'Identificador',
      width: 200,
    },
    {
      field: 'nome',
      headerName: 'Criado por',
      width: 200,
      valueGetter: (value, row) => row?.id_conta?.nome,
    },
    {
      field: 'dt_registro',
      headerName: 'Criado em',
      type: 'dateTime',
      width: 200,
      valueGetter: (value, row) => new Date(row.dt_registro),
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<Icon icon="solar:trash-bin-minimalistic-linear" width="20" />}
            label="Cancel"
            className="textPrimary"
            onClick={(e) => {
              handleDelete(id);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    getOrderList();
  }, []);

  async function getOrderList() {
    setLoading(true);
    await api
      .get(`/registroPage/${sessionStorage.getItem('idConta')}/*/coleta-p/*/*/${identificador || '*'}/*/*/*/0`, {})
      .then((response) => {
        const res = response.data.slice(0, -1).map((item, index) => ({
          ...item,
          id: index,
        }));
        setOrders(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClick(row) {
    const id_order = row?._id;
    navigate(`/orders/details/${id_order}`, { state: { pedido: row } });
  }

  function handleDelete(id) {
    const item = orders.find((row) => row.id === id);
    setSelectedOrder(item._id);
  }

  function deleteOrder() {
    setLoading(true);
    api
      .delete(
        `/registro/${selectedOrder}?id_conta=${sessionStorage.getItem('idConta')}&=id_usuario=${sessionStorage.getItem('idUser')}`
      )
      .then(
        (response) => {
          getOrderList();
          setMessage({
            type: 'success',
            title: t('messages.success'),
            message: t('settings.clean_base.success_message'),
          });
        },
        (error) => {
          setMessage({
            type: 'error',
            title: t('messages.errors.error'),
            message: t('messages.errors.500'),
          });
        }
      )
      .finally(() => {
        setSelectedOrder();
        setLoading(false);
      });
  }

  return (
    <>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h1">{t('common.demand')}s</Typography>
          <Stack direction="row" spacing={1}>
            <Dropdown color="dark" text={`${t('actions.import')}`}>
              <MenuItem
                onClick={(e) => {
                  navigate('/orders/upload/xml');
                }}
              >
                XML
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  navigate('/orders/upload/csv');
                }}
              >
                CSV
              </MenuItem>
            </Dropdown>
            <Button
              variant="contained"
              onClick={(e) => {
                navigate('/orders/details');
              }}
            >
              {t('actions.create')}
            </Button>
          </Stack>
        </Stack>

        <Stack spacing={3} as={Paper} sx={{ borderRadius: 3, p: 3 }}>
          <Stack spacing={3} sx={{ borderRadius: 1, p: 3, backgroundColor: 'primary.lighter' }}>
            <Typography variant="h6" color="primary">
              {t('common.filter')}
            </Typography>
            <Grid2 container>
              <Grid2 item sm={12} md={3}>
                <InputLabel>Identificador</InputLabel>
                <TextField
                  size="small"
                  value={identificador}
                  onChange={(e) => {
                    setIdentificador(e.target.value);
                  }}
                  fullWidth
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon="mingcute:search-line" />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid2>
            </Grid2>
            <Grid2 container justifyContent="end">
              <Button
                variant="contained"
                onClick={(e) => {
                  getOrderList();
                }}
              >
                {t('actions.search')}
              </Button>
            </Grid2>
          </Stack>

          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={orders}
            columns={columns}
            disableRowSelectionOnClick={true}
            density="comfortable"
            onRowClick={(row) => {
              handleClick(row.row);
            }}
            sx={{
              border: 0,
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            getRowHeight={({ id, densityFactor }) => {
              if (id % 2 === 0) {
                return 50 * densityFactor;
              }

              return null;
            }}
            slotProps={{
              columnsManagement: {
                toggleAllMode: 'filteredOnly',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </Stack>
      </Stack>

      <Loading show={loading} />

      {selectedOrder && (
        <CustomModal
          icon="hugeicons:delete-02"
          title={`${t('actions.delete')}  ${t('common.demand')}`}
          onConfirm={(e) => {
            deleteOrder();
          }}
          onClose={(e) => {
            setSelectedOrder();
          }}
        >
          <Typography variant="subtitle" component="p">
            {t('messages.warning_delete')}
          </Typography>
          <Typography variant="subtitle" color="error">
            {t('messages.warning')}
          </Typography>
        </CustomModal>
      )}

      {message ? (
        <Alert
          type={message.type}
          title={message.title}
          message={message.message}
          onClose={(e) => {
            setMessage(e);
          }}
        />
      ) : (
        ''
      )}
    </>
  );
}
