import { useEffect, useState } from "react";

import secureLocalStorage from "react-secure-storage";

import { t } from "i18next";

import api from "../../../services/api";

import Alert from "../../../components/ui/Alert";

import {
	Backdrop,
	Button,
	Checkbox,
	CircularProgress,
	Container,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import { TaskDetail } from "./TaskDetail";

//----------------------------------------------------------------

export default function ConfigTask() {
	const [loading, setLoading] = useState();

	const [message, setMessage] = useState();

	const [userData, setUserData] = useState([]);

	const [tarefas, setTarefas] = useState([{}]);

	useEffect(() => {
		getConfig();
	}, []);

	function getConfig() {
		setLoading(true);
		api
			.get(`/usuario/${sessionStorage.getItem("idConta")}/*/*/*/*`, {})
			.then((response) => {
				const data = response.data.find((i) => i._id);
				if (!data) {
					throw "error";
				}

				setTarefas(data?.tarefa.length ? data?.tarefa : [{}]);
				setUserData(data);
			})
			.catch(() => {
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const handleChange = (updatedTask, index) => {
		setTarefas((prevTarefas) => prevTarefas.map((task, i) => (i === index ? updatedTask : task)));
	};

	const handleDelete = (index) => {
		setTarefas((prevTarefas) => prevTarefas.filter((_, i) => i !== index));
	};

	function handleSave() {
		setLoading(true);

		const result = {
			...userData,
			id_usuario: userData?._id,
			tarefa: tarefas,
		};

		let option = {
			headers: { "Content-Type": ["application/json"] },
		};

		api
			.post("/usuario", [result], option)
			.then((response) => {
				setMessage({
					type: "success",
					message: t("messages.success_save"),
					title: t("messages.success"),
				});
			})
			.catch(() => {
				setMessage({
					type: "error",
					message: t("messages.errors.500"),
					title: t("messages.errors.error"),
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<>
			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Container>
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<div>
							<Typography variant="h1">{t("settings.tasks")}</Typography>{" "}
							<Typography variant="subtitle">
								{t("actions.program")} {t("settings.tasks")}
							</Typography>
						</div>
						<Stack direction="row" spacing={1}>
							<Button
								variant="outlined"
								startIcon={<Icon icon="mage:plus" />}
								onClick={() => {
									setTarefas([...tarefas, {}]);
								}}
							>
								{t("settings.tasks")}
							</Button>
							<Button
								color="error"
								variant="contained"
								onClick={() => {
									handleSave();
								}}
							>
								{t("actions.save")}
							</Button>
						</Stack>
					</Stack>
					<Stack spacing={2}>
						{!loading &&
							tarefas?.map((tarefa, index) => (
								<Stack as={Paper} sx={{ borderRadius: 3, p: 3 }} spacing={3}>
									<Stack direction="row" justifyContent="space-between" alignItems="center">
										<Typography variant="h6">
											{t("settings.task")} {index + 1}
										</Typography>
										<IconButton onClick={() => handleDelete(index)}>
											<Icon icon="mage:trash" />
										</IconButton>
									</Stack>
									<TaskDetail
										task={tarefa}
										onReturn={(e) => {
											handleChange(e, index);
										}}
									/>
								</Stack>
							))}
					</Stack>
				</Stack>
			</Container>

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(e);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
