import { useEffect, useState } from "react";

import {
	Box,
	Button,
	Checkbox,
	Chip,
	Container,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid2,
	IconButton,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";

import { t } from "i18next";

import api from "../../services/api";

import dayjs from "dayjs";

import Loading from "../../components/ui/Loading";

import { Icon } from "@iconify/react/dist/iconify.js";

import DataGrid from "../../components/datagrid";

import Alert from "../../components/ui/Alert";

var relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

// ------------------------------------------------------

const columns = [
	{
		field: "data",
		headerName: "Data",
		type: "date",
		width: 160,
		renderCell: (params) => (params.value ? dayjs(params.value).format("DD MMM, YY HH:mm") : ""),
	},
	{
		field: "tag",
		headerName: "Item",
		width: 250,
		renderCell: (params) => (
			<Stack>
				<Typography variant="subtitle2">{params?.row?.descricao}</Typography>
				<Typography variant="subtitle">{params?.row?.tag}</Typography>
			</Stack>
		),
	},
	{
		field: "mudanca",
		headerName: "Mudanças",
		width: 380,
		renderCell: (params) => (
			<Stack>
				{params.value?.map((i, index) => (
					<Stack
						key={index}
						direction="row"
						spacing={0.5}
						sx={{
							textWrap: "nowrap",
							whiteSpace: "nowrap",
						}}
					>
						<Typography sx={{ fontWeight: 600, textTransform: "capitalize" }}>{i?.campo}</Typography>
						<Typography sx={{ textTransform: "lowercase" }}>{t("log." + i?.action)}</Typography>
						<Typography color="primary">{i?.valor_atual}</Typography>
					</Stack>
				))}
			</Stack>
		),
	},
	{
		field: "type",
		headerName: "Ação",
		width: 180,
		renderCell: (params) => (
			<Chip
				icon={<Icon icon={params.row.icon} width={16} />}
				label={params?.row?.type ? t("log." + params.row.type) : ""}
			/>
		),
	},

	{
		field: "usuario",
		headerName: "Usuario",
		width: 180,
		renderCell: (params) => (
			<Stack direction="row" spacing={1} alignItems="center">
				<Icon icon="lets-icons:user-cicrle-duotone" width={20} />
				<Typography>{params?.row?.usuario}</Typography>
			</Stack>
		),
	},
];

export default function ItemsActivity() {
	dayjs.locale("pt-br");

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState();

	const [logs, setLogs] = useState([]);

	const [unfilteredLog, setUnfilteredLog] = useState([]);

	// Filters
	const [filterUsuario, setFilterUsuario] = useState("");

	const [filterTag, setFilterTag] = useState("");

	const [filterType, setFilterType] = useState("");

	const [filterAcao, setFilterAcao] = useState("");

	// Lista
	const [usuarios, setUsuarios] = useState([]);

	const [tags, setTags] = useState([]);

	useEffect(() => {
		function getUser() {
			api
				.get(`/usuario/${sessionStorage.getItem("idConta")}/*/*/*/*`)
				.then((res) => {
					const data = res?.data?.filter((i) => i?._id);
					setUsuarios(data);
				})
				.catch(() => {
					setMessage({
						type: "error",
						message: t("messages.errors.500"),
						title: t("messages.errors.error"),
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}

		getUser();
	}, []);

	useEffect(() => {
		function getHistory() {
			setLoading(true);
			api
				.get(`/conta_historico/${sessionStorage.getItem("idConta")}/${filterUsuario || "*"}`, {
					headers: { key: "icdeva@23!" },
				})
				.then((res) => {
					const data = res?.data?.flatMap((item) =>
						item.acoes.map((acao) => ({
							...acao,
							id_usuario: item?.id_usuario,
							usuario: item?.id_usuario
								? usuarios.find((i) => i?._id == item.id_usuario)?.nome || "Usuário deletado"
								: "",
						}))
					);
					const formatedData = data
						.filter((i) => i?.acao.includes("item"))
						.map((i, index) => {
							const acao = i?.acao.includes("Atualizacao")
								? { acao: "update", icon: "mynaui:edit-one" }
								: i?.acao.includes("Criacao")
									? { acao: "create", icon: "solar:traffic-economy-line-duotone" }
									: { acao: i.acao, icon: "solar:tuning-2-linear" };

							const valor = i?.valor;
							const item_atual = Array.isArray(valor) ? valor[0].valor_atual : valor;
							const item_anterior = Array.isArray(valor) ? valor[0].valor_anterior : null;

							var change = null;
							if (item_anterior) {
								change = compareChanges(item_anterior, item_atual);
							} else {
								change = [{ campo: "Item", action: "created" }];
							}

							return {
								...i,
								acoes: acao?.acao,
								type: acao?.acao,
								icon: acao?.icon,
								data: new Date(i.dt_acao),
								tag: item_atual?.tag,
								descricao: item_atual?.id_categoria?.descricao,
								mudanca: change,
								usuario: i?.usuario,
								valor: valor,
								id: index,
							};
						});

					const sortedData = formatedData.sort((a, b) => dayjs(b.dt_acao).valueOf() - dayjs(a.dt_acao).valueOf());
					setLogs(sortedData);
					setUnfilteredLog(sortedData);
					setTags([...new Set(sortedData.filter((i) => i?.tag).map((i) => i.tag))]);
				})
				.catch(() => {
					setMessage({
						type: "error",
						message: t("messages.errors.500"),
						title: t("messages.errors.error"),
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}

		if (usuarios?.length) {
			getHistory();
		}
	}, [usuarios, filterUsuario]);

	function compareChanges(objAnterior, objAtual) {
		let diferencas = [];

		for (let chave in objAtual) {
			if (objAtual.hasOwnProperty(chave)) {
				let valorAnterior = objAnterior[chave];
				let valorAtual = objAtual[chave];

				const ignoreKeys = ["dt_alteracao", "id_usuario"];

				const ignore = ignoreKeys.some((expectedKey) => chave.includes(expectedKey));

				if (JSON.stringify(valorAnterior) !== JSON.stringify(valorAtual) && !ignore && valorAtual) {
					// Comparação considerando valores vazios e tipos diferentes
					diferencas.push({
						valor_atual: valorAtual,
						action: valorAtual ? "updated_to" : "deleted",
						campo: getLabel(chave),
					});
				}
			}
		}

		return diferencas;
	}

	function getLabel(field) {
		if (field.includes("id_nivel_loc_")) {
			return field.replace("id_nivel_loc_", "nivel ");
		}

		if (field.includes("id_")) {
			return field.replace("id_", "");
		}

		if (field.includes("_")) {
			return field.replace(/_/g, " ");
		}

		return field;
	}

	function handleFilter() {
		var filtered = unfilteredLog;
		if (filterTag) {
			filtered = filtered.filter((i) => i.tag === filterTag);
		}
		if (filterType) {
			filtered = filtered.filter((i) => i.type === filterType);
		}
		if (filterAcao) {
			console.log(filterAcao);
			console.log(filtered);

			filtered = filtered.filter((i) => i?.mudanca?.some((m) => m.campo.includes(filterAcao)));
		}

		setLogs(filtered);
	}

	useEffect(() => {
		handleFilter();
	}, [filterAcao]);

	return (
		<>
			<Container>
				<Stack spacing={3}>
					<Typography variant="h1">{t("common.items_history")}</Typography>

					<Stack as={Paper} spacing={3} sx={{ p: 3, borderRadius: 3 }}>
						<Stack spacing={3} sx={{ p: 3, backgroundColor: "primary.lighter", borderRadius: 3 }}>
							<Grid2 container spacing={3}>
								<Grid2 size={3}>
									<FormControl fullWidth>
										<FormLabel>Tag</FormLabel>
										<Select
											size="small"
											value={filterTag}
											onChange={(e) => {
												setFilterTag(e.target.value);
											}}
										>
											<MenuItem value={null}>--</MenuItem>
											{tags.map((u) => (
												<MenuItem value={u}>{u}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid2>
								<Grid2 size={3}>
									<FormControl fullWidth>
										<FormLabel>{t("common.user")}</FormLabel>
										<Select
											size="small"
											value={filterUsuario}
											onChange={(e) => {
												setFilterUsuario(e.target.value);
											}}
										>
											<MenuItem value={null}>--</MenuItem>
											{usuarios.map((u) => (
												<MenuItem value={u?._id}>{u?.nome}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid2>
								<Grid2 size={3}>
									<FormControl fullWidth>
										<FormLabel>Tipo</FormLabel>
										<Select
											size="small"
											value={filterType}
											onChange={(e) => {
												setFilterType(e.target.value);
											}}
										>
											<MenuItem value={null}>--</MenuItem>
											<MenuItem value="create">{t("log.create")}</MenuItem>
											<MenuItem value="update">{t("log.update")}</MenuItem>
										</Select>
									</FormControl>
								</Grid2>
							</Grid2>
							<Stack direction="row" justifyContent="flex-end">
								<Button
									variant="contained"
									onClick={() => {
										handleFilter();
									}}
								>
									{t("actions.search")}
								</Button>
							</Stack>
						</Stack>

						<Stack direction="row" spacing={1} sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Button
								color={!filterAcao ? "primary" : "secondary"}
								sx={{ borderRadius: 0.5, borderBottom: !filterAcao ? 2 : 0 }}
								startIcon={<Icon icon="solar:tuning-2-line-duotone" />}
								onClick={() => {
									setFilterAcao();
								}}
							>
								Histórico
							</Button>
							<Button
								color={filterAcao == "nivel" ? "primary" : "secondary"}
								sx={{ borderRadius: 0.5, borderBottom: filterAcao == "nivel" ? 2 : 0 }}
								startIcon={<Icon icon="hugeicons:truck-delivery" />}
								onClick={() => {
									setFilterAcao("nivel");
								}}
							>
								Movimentações
							</Button>
							<Button
								color={filterAcao == "Item" ? "primary" : "secondary"}
								sx={{ borderRadius: 0.5, borderBottom: filterAcao == "create" ? 2 : 0 }}
								startIcon={<Icon icon="fluent:notepad-sparkle-32-regular" />}
								onClick={() => {
									setFilterAcao("Item");
								}}
							>
								Itens criados
							</Button>
						</Stack>

						<DataGrid
							header={columns}
							tableData={logs}
							onClick={(e) => {
								//navigate(`details/${e.id_conta}`, { state: { usuario: e } });
							}}
							onSelect={(index) => {
								//setSelectedRows(index);
							}}
						/>
					</Stack>
				</Stack>
			</Container>

			<Loading show={loading} />

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(null);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
