import { useLocation, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import { Box, Button, Container, Grid, IconButton, Stack, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import { grey } from "@mui/material/colors";
import secureLocalStorage from "react-secure-storage";
import { t } from "i18next";
import { useState } from "react";

//-----------------------------------------------------------------

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

export default function NTIUploadPhoto() {
	const navigate = useNavigate();

	const location = useLocation();

	const props = location?.state || {};

	const { foto } = props;

	const [photo, setPhoto] = useState(foto || "");

	function handleUpload({ target }) {
		const file = target.files[0];
		const reader = new FileReader();

		reader.onloadend = () => {
			const img = new Image();
			img.src = reader.result;

			img.onload = () => {
				const canvas = document.createElement("canvas");
				const MAX_SIZE = 1000; // Defina o tamanho máximo de largura ou altura que você deseja

				let width = img.width;
				let height = img.height;

				// Verifica se é necessário redimensionar
				if (width > height && width > MAX_SIZE) {
					height *= MAX_SIZE / width;
					width = MAX_SIZE;
				} else if (height > MAX_SIZE) {
					width *= MAX_SIZE / height;
					height = MAX_SIZE;
				}

				// Redimensiona o canvas
				canvas.width = width;
				canvas.height = height;

				const ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0, width, height);

				// Converte o canvas para uma imagem comprimida
				canvas.toBlob(
					(blob) => {
						if (blob.size > 1000000) {
							// Compressão adicional, ajusta a qualidade para ficar abaixo de 1MB
							canvas.toBlob(
								(compressedBlob) => {
									const compressedReader = new FileReader();
									compressedReader.onloadend = () => {
										setPhoto(compressedReader.result);
									};
									compressedReader.readAsDataURL(compressedBlob);
								},
								"image/jpeg",
								0.8
							); // 0.8 é a qualidade de compressão (de 0 a 1)
						} else {
							const newReader = new FileReader();
							newReader.onloadend = () => {
								setPhoto(newReader.result);
							};
							newReader.readAsDataURL(blob);
						}
					},
					"image/jpeg",
					0.9
				); // 0.9 é a qualidade inicial
			};
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	}

	function handleSavePhoto() {
		const data = {
			...props,
			foto: photo,
		};
		navigate("/nti/confirm-data", { state: { ...data } });
	}

	return (
		<>
			<Stack spacing={3}>
				<Stack direction="row">
					<Button
						color="dark"
						startIcon={<Icon icon="iconamoon:arrow-left-2-bold" />}
						onClick={() => {
							navigate("/nti/qrcode");
						}}
					>
						Voltar
					</Button>
				</Stack>

				<Stack direction="row" alignItems="center" spacing={2}>
					<IconButton color="primary" sx={{ boxShadow: 1 }}>
						<Icon icon="solar:camera-add-line-duotone" width={16} />
					</IconButton>
					<Typography variant="h1">Tire uma foto do objeto</Typography>
				</Stack>

				{photo ? (
					<Stack direction="row" justifyContent="center" alignItems="flex-start" sx={{ padding: 5 }}>
						<Box
							component="img"
							src={photo}
							sx={{
								marginTop: 2,
								borderRadius: 3,
								backgroundColor: grey[400],
								width: "200px",
								height: "200px",
							}}
						></Box>
						<IconButton
							onClick={(e) => {
								setPhoto(null);
							}}
						>
							<Icon icon="iwwa:delete" width={14} />
						</IconButton>
					</Stack>
				) : (
					<Box
						component="label"
						role={undefined}
						variant="standard"
						tabIndex={-1}
						onDrop={(e) => {
							handleUpload(e);
						}}
						sx={{
							p: 2,
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							border: 1,
							borderStyle: "dashed",
							borderColor: "grey.400",
							borderRadius: 1,
							backgroundColor: "grey.200",
						}}
					>
						<Box sx={{ color: "primary.main", pb: 3 }}>
							<Icon icon="fluent:scan-camera-16-regular" width="100" />
						</Box>
						<Typography variant="subtitle" align="center" paragraph={false}>
							Adicione fotos do produto
						</Typography>
						<Typography variant="subtitle" align="center" paragraph={false}>
							em formato .png, .jpeg, .svg
						</Typography>
						<VisuallyHiddenInput
							type="file"
							accept="image/*"
							onChange={(e) => {
								handleUpload(e);
							}}
						/>
					</Box>
				)}

				<Stack spacing={2}>
					<Button
						variant="outlined"
						fullWidth
						component="label"
						role={undefined}
						tabIndex={-1}
						startIcon={<Icon icon="fluent:scan-camera-16-regular" />}
					>
						Upload Imagem
						<VisuallyHiddenInput
							type="file"
							accept="image/*"
							onChange={(e) => {
								handleUpload(e);
							}}
						/>
					</Button>

					{photo ? (
						<Button
							variant="contained"
							onClick={(e) => {
								handleSavePhoto();
							}}
							endIcon={<Icon icon="ic:round-double-arrow" width={12} />}
							fullWidth
						>
							Continuar
						</Button>
					) : (
						""
					)}
				</Stack>
			</Stack>
		</>
	);
}
