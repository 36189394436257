import {
	Timeline,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineConnector,
	TimelineSeparator,
	TimelineOppositeContent,
	timelineOppositeContentClasses,
	timelineItemClasses,
} from "@mui/lab";

import {
	Backdrop,
	Box,
	Button,
	Container,
	Divider,
	Grid2,
	IconButton,
	Paper,
	Snackbar,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";

import dayjs from "dayjs";

import { useEffect, useState } from "react";

import { Icon } from "@iconify/react/dist/iconify.js";

import { t } from "i18next";

import "dayjs/locale/pt-br";

import React from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import api from "../../services/api";

import Loading from "../../components/ui/Loading";
import Maps from "../../components/Maps/Maps";

const formatKey = (key) => {
	return key.replace(/_/g, " ");
};

export default function RegistersTimeline() {
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	dayjs.locale("pt-br");

	const location = useLocation();

	const { redirect = "", filters = "" } = location?.state || {};

	const { id_item } = useParams();

	const [movimentacoes, setMovimentacoes] = useState([]);

	const [expandFoto, setExpandFoto] = useState();

	const [selectLocation, setSelectLocation] = useState("");

	useEffect(() => {
		getModuloTransport();
	}, []);

	async function getModuloTransport() {
		try {
			setLoading(true);
			const response = await api.get(`/rastreamentoItem/${sessionStorage.getItem("idConta")}/*/${id_item}`);
			const data = response.data.map((item) => ({
				...item,
				date: item.dt_entrega || "",
				status: item?.status || "Status Indefinido",
				observacao: item.observacao,
				imagem: item?.imagem || null,
				nome: item?.nome,
				contato: item?.telefone,
			}));
			setMovimentacoes(data.reverse());
		} catch (error) {
			console.error("Error fetching activities:", error);
		} finally {
			setLoading(false);
		}
	}

	return (
		<>
			<Container>
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between">
						<Typography variant="h1">Item</Typography>
						<Button
							variant="outlined"
							color="dark"
							onClick={() => {
								navigate(redirect, { state: { filters } });
							}}
						>
							{t("actions.exit")}
						</Button>
					</Stack>
					<Grid2 container spacing={2}>
						<Grid2 size="grow">
							<Stack as={Paper} spacing={3} sx={{ borderRadius: 5, p: 3 }}>
								<Typography variant="h2">Histórico</Typography>
								{/* Display dos campos abertos (custom) */}
								{movimentacoes && movimentacoes?.length ? (
									<>
										<Timeline
											sx={{
												[`& .${timelineOppositeContentClasses.root}`]: {
													flex: 0.2,
													paddingLeft: 0,
												},
												[`& .${timelineItemClasses.root}:before`]: {
													flex: 0,
													padding: 0,
												},
											}}
										>
											{movimentacoes?.map((movimentacao, index) => (
												<>
													<TimelineItem>
														{/* Dot */}
														<TimelineSeparator>
															<TimelineDot variant="filled" color={index ? "grey" : "primary"} />
															{index === movimentacoes.length - 1 ? "" : <TimelineConnector />}
														</TimelineSeparator>

														{/* Lado direito - com as infos */}
														<TimelineContent>
															<Stack spacing={3}>
																<Stack direction="row" spacing={1} justifyContent="space-between" alignItems="start">
																	<Stack direction="row" spacing={1} sx={{ width: "80%" }}>
																		{/* Imagem - se tiver */}
																		{movimentacao?.imagem && (
																			<Tooltip title="Clique na imagem para expandir">
																				<Box
																					component="img"
																					src={
																						movimentacao?.imagem.includes(".jpeg")
																							? "https://api.inovacode.app.br/" + movimentacao?.imagem
																							: movimentacao?.imagem
																					}
																					sx={{
																						borderRadius: 3,
																						width: "100px",
																						height: "100px",
																						objectFit: "cover",
																					}}
																					onClick={() => {
																						setExpandFoto(movimentacao?.imagem);
																					}}
																				/>
																			</Tooltip>
																		)}

																		<div>
																			<Typography variant="h3">{movimentacao?.status}</Typography>
																			<Stack direction="row" spacing={1} alignItems="center">
																				<Icon icon="solar:calendar-line-duotone" />
																				<Typography variant="subtitle">
																					{dayjs(movimentacao?.dt_entrega).format("DD MMMM, YYYY HH:mm")}
																				</Typography>
																			</Stack>
																			<Stack direction="row" spacing={1} alignItems="center">
																				<Icon icon="heroicons:user-16-solid" />
																				<Typography variant="subtitle">{movimentacao?.nome}</Typography>
																			</Stack>
																			<Stack
																				direction="row"
																				spacing={1}
																				alignItems="center"
																				sx={{
																					color: "grey.500",
																					display: movimentacao?.contato ? "flex" : "none",
																				}}
																			>
																				<Icon icon="fluent:contact-card-28-regular" />
																				<Typography variant="subtitle">{movimentacao?.contato}</Typography>
																			</Stack>
																		</div>
																	</Stack>
																	<Tooltip
																		title={
																			!movimentacao?.campos?.lat || !movimentacao?.campos?.long
																				? "Localização não fornecida"
																				: ""
																		}
																	>
																		<span>
																			<Button
																				variant="outlined"
																				color="dark"
																				disabled={!movimentacao?.campos?.lat || !movimentacao?.campos?.long}
																				startIcon={<Icon icon="logos:google-maps" />}
																				onClick={() => {
																					setSelectLocation({
																						latitude: movimentacao?.campos?.lat,
																						longitude: movimentacao?.campos?.long,
																					});
																				}}
																			>
																				{t("common.locate")}
																			</Button>
																		</span>
																	</Tooltip>
																</Stack>

																<Stack direction="row" spacing={2}>
																	{/* Observacoes e Campos Abertos */}
																	<Stack
																		spacing={1}
																		sx={{
																			ml: 2,
																			width: "100%",
																			backgroundColor: "grey.100",
																			p: 2,
																			borderRadius: 3,
																		}}
																	>
																		<Typography variant="subtitle">Informações</Typography>
																		<Divider sx={{ borderStyle: "dashed", borderColor: "grey.400" }} />

																		{/* Campos Abertos */}
																		{movimentacao.campos &&
																			Object.entries(movimentacao.campos).map(([key, value]) => (
																				<Grid2 container spacing={1}>
																					<Grid2 size={5} sx={{ textTransform: "capitalize" }}>
																						<Typography variant="subtitle">{formatKey(key)}:</Typography>
																					</Grid2>
																					<Grid2 size="grow">
																						<Typography>
																							{String(value) === "true"
																								? t("common.yes")
																								: String(value) === "false"
																									? t("common.no")
																									: String(value)}
																						</Typography>
																					</Grid2>
																				</Grid2>
																			))}

																		{/* Observacão */}
																		<Grid2 container spacing={1}>
																			<Grid2 size={4}>
																				<Typography variant="subtitle">Observação:</Typography>
																			</Grid2>
																			<Grid2 size="grow">{movimentacao.observacao}</Grid2>
																		</Grid2>
																	</Stack>
																</Stack>
															</Stack>
														</TimelineContent>
													</TimelineItem>
												</>
											))}
										</Timeline>
									</>
								) : (
									<Stack justifyContent="center" alignItems="center" spacing={2} sx={{ color: "grey.400", p: 5 }}>
										<Icon icon="subway:error" width={48} />
										<Typography variant="subtitle">Sem registros ainda</Typography>
									</Stack>
								)}
							</Stack>
						</Grid2>
						<Grid2 size={{ xs: 12, md: 4 }}>
							<Stack as={Paper} spacing={3} sx={{ borderRadius: 5, p: 3 }}>
								<Typography variant="h4">Informações atuais</Typography>
								<Grid2 container spacing={1}>
									<Grid2 size={4}>
										<Typography variant="subtitle">Status</Typography>
									</Grid2>
									<Grid2 size={8}>{movimentacoes[0]?.status}</Grid2>
									<Grid2 size={4}>
										<Typography variant="subtitle">Item</Typography>
									</Grid2>
									<Grid2 size={8}>
										<Typography>{movimentacoes[0]?.id_item?.id_categoria?.descricao}</Typography>
									</Grid2>
									<Grid2 size={4}>
										<Typography variant="subtitle">EPC</Typography>
									</Grid2>
									<Grid2 size={8}>
										<Typography>{movimentacoes[0]?.id_item?.tag}</Typography>
									</Grid2>
									<Grid2 size={4}>
										<Typography variant="subtitle">Data</Typography>
									</Grid2>
									<Grid2 size={8} sx={{ textTransform: "capitalize" }}>
										{movimentacoes[0]?.dt_entrega
											? dayjs(movimentacoes[0]?.dt_entrega).format("DD MMM, YYYY HH:mm")
											: ""}
									</Grid2>
								</Grid2>
							</Stack>
						</Grid2>
					</Grid2>
				</Stack>
			</Container>

			<Loading show={loading} />

			<Backdrop
				sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
				open={expandFoto}
				onClick={() => {
					setExpandFoto();
				}}
			>
				<Box
					component="img"
					src={expandFoto?.includes(".jpeg") ? "https://api.inovacode.app.br/" + expandFoto : expandFoto}
					sx={{
						borderRadius: 1,
						minWidth: "35vw",
						maxWidth: "50vw",
						maxHeight: "90vh",
						objectFit: "cover", // Garante que a imagem preencha o espaço sem distorcer
					}}
				/>
			</Backdrop>

			{selectLocation && (
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					open={selectLocation}
					onClose={() => {
						setSelectLocation();
					}}
				>
					<Stack as={Paper} sx={{ p: 3, borderRadius: 3 }}>
						<Maps
							latitude={selectLocation?.latitude}
							longitude={selectLocation?.longitude}
							zoom={15}
							width="30vw"
							height="200px"
						/>
					</Stack>
				</Snackbar>
			)}
		</>
	);
}
