import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

// Interceptor para adicionar o token ao cabeçalho das requisições
api.interceptors.request.use(
	async (config) => {
		const token = secureLocalStorage.getItem("auth_token");
		const id_conta = sessionStorage.getItem("idConta");

		if (token && id_conta) {
			config.headers.id_conta = id_conta;
			config.headers.Authorization = `Bearer ${token}`;
		} else {
			config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InNvY2tldCIsImlhdCI6MTczODk0NzA0NSwiZXhwIjoxNzM4OTc1ODQ1fQ._ZvpzF1JTVKCRIWctHTDTF_8WZU4vYz2mFE8Cg_ghM8`;
		}

		return config;
	},
	(error) => Promise.reject(error)
);

export default api;
