import { useEffect, useState } from "react";

import { t } from "i18next";

import Loading from "../../../components/ui/Loading";

import api from "../../../services/api";

import {
	Box,
	Button,
	Checkbox,
	Chip,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid2,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Paper,
	Select,
	Stack,
	styled,
	Typography,
} from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import {
	DataGrid,
	GridToolbar,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { ptBR } from "@mui/x-data-grid/locales";

import { useLocation, useNavigate } from "react-router-dom";

import Alert from "../../../components/ui/Alert";

import ImportCSV from "../../../components/ImportXML/ImportCSV.js";

import ConfigImport from "../../../components/ImportXML/ConfigImport.js";

import { green, grey, red, yellow } from "@mui/material/colors";
import CustomModal from "../../../components/ui/CustomModal.js";

// ----------------------------------------------------------

const columns = [
	{
		field: "id",
		headerName: "ID",
		width: 90,
	},
	{
		headerName: "Status",
		field: "status",
		width: 200,
		filterable: true,
		renderCell: (params) => (
			<Chip
				label={params.row.status}
				sx={{
					borderRadius: 1,
					fontWeight: 600,
					backgroundColor: params.row.status.includes("Erro")
						? red[100]
						: params.row.status == "Correto"
							? green[50]
							: "",
					color: params.row.status.includes("Erro")
						? red[600]
						: params.row.status == "Correto"
							? green[600]
							: grey[600],
				}}
			/>
		),
	},
	{ headerName: "Tag", field: "tag", width: 300 },
	{ headerName: "Categoria", field: "categoria", width: 300 },
	{ headerName: "EAN", field: "ean" },
	{ headerName: "Marca", field: "marca" },
	{ headerName: "Modelo", field: "modelo" },
	{ headerName: "Nível Loc 1", field: "nivel_loc_1" },
	{ headerName: "Nível Loc 2", field: "nivel_loc_2" },
	{ headerName: "Nível Loc 3", field: "nivel_loc_3" },
	{ headerName: "Nível Loc 4", field: "nivel_loc_4" },
	{ headerName: "Informação Complementar 1", field: "inf_compl_1" },
	{ headerName: "Informação Complementar 2", field: "inf_compl_2" },
	{ headerName: "Informação Complementar 3", field: "inf_compl_3" },
	{ headerName: "Informação Complementar 4", field: "inf_compl_4" },
	{ headerName: "Informação Complementar 5", field: "inf_compl_5" },
	{ headerName: "Informação Complementar 6", field: "inf_compl_6" },
	{ headerName: "Informação Complementar 7", field: "inf_compl_7" },
	{ headerName: "Informação Complementar 8", field: "inf_compl_8" },
	{ headerName: "Informação Complementar 9", field: "inf_compl_9" },
	{ headerName: "Informação Complementar 10", field: "inf_compl_10" },
	{ headerName: "Valor", field: "valor" },
	{ headerName: "Parceiro", field: "parceiro" },
	{ headerName: "Observação", field: "observacao" },
	{ headerName: "Ativo", field: "ativo" },
	{ headerName: "Foto", field: "foto" },
	{ headerName: "ID Externo", field: "id_externo" },
	{ headerName: "Item Vinculado", field: "id_item_vinculado" },
	{ headerName: "UP 1", field: "up_1" },
	{ headerName: "UP 2", field: "up_2" },
	{ headerName: "UP 3", field: "up_3" },
	{ headerName: "UP 4", field: "up_4" },
	{ headerName: "UP 5", field: "up_5" },
];

const keys = [
	"tag",
	"id_categoria",
	"ean",
	"id_nivel_loc_1",
	"id_nivel_loc_2",
	"id_nivel_loc_3",
	"id_nivel_loc_4",
	"inf_compl_1",
	"inf_compl_2",
	"inf_compl_3",
	"inf_compl_4",
	"inf_compl_5",
	"inf_compl_6",
	"inf_compl_7",
	"inf_compl_8",
	"inf_compl_9",
	"inf_compl_10",
	"id_marca",
	"id_modelo",
	"valor",
	"id_parceiro",
	"observacao",
	"ativo",
	"foto",
	"id_externo",
	"id_item_vinculado",
	"up_1",
	"up_2",
	"up_3",
	"up_4",
	"up_5",
];

export default function ImportItensCSV() {
	const navigate = useNavigate();

	const location = useLocation();

	const id_conta = sessionStorage.getItem("idConta");

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState(); // Armazena mensagens e erros

	const [configImport, setConfigImport] = useState(location?.state?.config || null); // Configuração do import

	const [modelos, setModelos] = useState([]); // Configuração do import

	const [categorias, setCategorias] = useState([]); // Lista de categorias na base

	const [itemsList, setitemsList] = useState([]); // Lista de itens na base

	const [parceiros, setParceiros] = useState([]); // Lista de parceiros na base

	// Lista de niveis na base
	const [nivel1, setNivel1] = useState([]);

	const [nivel2, setNivel2] = useState([]);

	const [nivel3, setNivel3] = useState([]);

	const [nivel4, setNivel4] = useState([]);

	const [marcas, setMarcas] = useState([]);

	const [items, setItems] = useState([]); // Itens que foram importados

	const [upComming, setUpComming] = useState(); // Itens que serão importados

	const [upMarcas, setUpMarcas] = useState([]); // Marcas que serão importadas

	const [importCount, setImportCount] = useState(); // Contagem de itens por status

	const [fileHeader, setFileHeader] = useState();

	const [fileData, setFileData] = useState([]);

	const [showModal, setShowModal] = useState(false);

	const [hasHeader, setHasHeader] = useState(false);

	useEffect(() => {
		function getModels() {
			api.get(`/conta_config/${sessionStorage.getItem("idConta")}`).then((response) => {
				const data = response.data.find((i) => i?._id);

				const modules = data?.import?.itens || [];

				setModelos(modules);
			});
		}
		getModels();
	}, []);

	useEffect(() => {
		if (location?.state?.preLoad) {
			handleUpload(location?.state?.preLoad);
		}
	}, []);

	useEffect(() => {
		async function getItems() {
			setLoading(true);
			await api.get(`/item/${id_conta}/*/*/*/*/*/*/*/*/*/*/*/*/*`).then(
				(response) => {
					setitemsList(response.data.filter((item) => item._id));
					setLoading(false);
				},
				(error) => {
					setLoading(false);
				}
			);
		}

		getItems();
	}, []);

	useEffect(() => {
		async function getcategorias() {
			await api
				.get(`/categoria/${sessionStorage.getItem("idConta")}/*/*/*/1`, {})
				.then((response) => {
					setCategorias(response.data.filter((item) => item._id));
				})
				.finally(() => {});

			await api
				.get(`/infs_compl/${sessionStorage.getItem("idConta")}`)
				.then((response) => {
					setMarcas(response.data?.filter((item) => item._id));
				})
				.finally(() => {});
		}

		getcategorias();
	}, []);

	useEffect(() => {
		async function getPartners() {
			await api
				.get(`/parceiro/${id_conta}/*/*/*/1`, {})
				.then((response) => {
					setParceiros(response.data.filter((item) => item._id));
				})
				.finally(() => {});
		}

		getPartners();
	}, []);

	useEffect(() => {
		async function getLocates() {
			await api
				.get(`/nivel_loc1/${sessionStorage.getItem("idConta")}/*/*/*/*`, {})
				.then((response) => {
					setNivel1(response.data.filter((item) => item._id));
				})
				.finally(() => {});
			await api
				.get(`/nivel_loc2/${sessionStorage.getItem("idConta")}/*/*/*/*/*`, {})
				.then((response) => {
					setNivel2(response.data.filter((item) => item._id));
				})
				.finally(() => {});
			await api
				.get(`/nivel_loc3/${sessionStorage.getItem("idConta")}/*/*/*/*/*`, {})
				.then((response) => {
					setNivel3(response.data.filter((item) => item._id));
				})
				.finally(() => {});
			await api
				.get(`/nivel_loc4/${sessionStorage.getItem("idConta")}/*/*/*/*/*`, {})
				.then((response) => {
					setNivel4(response.data.filter((item) => item._id));
				})
				.finally(() => {});
		}

		getLocates();
	}, []);

	function getID() {
		return "xxxxxxxx-yxxx".replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	function getItemID() {
		// Obtém a data e a hora atuais
		const now = new Date();

		// Formata a data no formato AAAAMMDD
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0"); // Mês de 0-11 para 1-12
		const day = String(now.getDate()).padStart(2, "0"); // Dia do mês de 1-31

		// Concatena a data e a hora no formato AAAAMMDD-HHMMSS
		const formattedDateTime = `${year}${month}${day}`;

		// Gera a parte aleatória do ID
		const randomID = "xxxxxxxx-yxxx".replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});

		// Combina a data e a hora com o ID aleatório
		return `${randomID}_${sessionStorage.getItem("idConta")}${formattedDateTime}`;
	}

	function getStatus(tag, categoria, duplicado, valor) {
		const regex = /^[0-9A-Fa-f]+$/;
		if (typeof tag === "string" && !regex.test(tag)) {
			return "Erro: Tag Inválida";
		}

		if (!categoria) {
			return "Erro: Categoria Ausente";
		}

		if (duplicado) {
			return "Erro: Tag Duplicada";
		}

		if (valor && isNaN(valor)) {
			return "Erro: Campo valor em formato de texto";
		}

		if (itemsList.find((item) => item.tag === tag)) {
			return "Registrado";
		}

		return "Correto";
	}

	useEffect(() => {
		if (fileData.length && !configImport) {
			setShowModal(true);
		} else if (fileData.length) {
			handleUpload(fileData);
		}
	}, [fileData]);

	function handleFile(e) {
		const { header, data } = e;

		setFileHeader(header);

		setFileData(data);
	}

	function handleUpload(file) {
		if (configImport) {
			const { upcomingFields, XMLFields } = configImport;
			const rows = file.map((item) => {
				const row = {};
				upcomingFields.forEach((field, index) => {
					row[field] = item[XMLFields[index]];
				});
				return row;
			});
			handleUploadFile(rows);
		} else {
			const rows = file.map((item) => {
				const row = {};
				keys.forEach((key, index) => {
					row[key] = Object.values(item)[index];
				});

				return row;
			});

			handleUploadFile(rows);
		}
	}

	async function handleSave() {
		let option = { headers: { "Content-Type": ["application/json"] } };

		await api.post(`/itensDef/${sessionStorage.getItem("idConta")}`, [upComming], option).then(
			(response) => {
				if (upMarcas.length) {
					api.post("/infs_compl", upMarcas, option).then(
						(response) => {
							setMessage({
								type: "success",
								title: t("messages.success"),
								message: t("settings.clean_base.success_message"),
							});
						},
						(error) => {
							setMessage({
								type: "error",
								title: t("messages.errors.error"),
								message: t("messages.errors.500"),
							});
						}
					);
				} else {
					setMessage({
						type: "success",
						title: t("messages.success"),
						message: t("settings.clean_base.success_message"),
					});
				}
			},
			(error) => {
				setMessage({
					type: "error",
					title: t("messages.errors.error"),
					message: t("messages.errors.500"),
				});
			}
		);
	}

	function handleNavigate() {
		if (fileHeader) {
			navigate("/items/import/config/csv", { state: { xmlfields: fileHeader, preLoad: fileData } });
		} else {
			navigate("/items/import/config/csv", { state: { config: configImport, preLoad: fileData } });
		}
	}

	function handleTemplate() {
		const data = columns.slice(2).map((item) => item.headerName); // Obter apenas os campos sem o ponto e vírgula
		const header = data.join(";"); // Concatena com ponto e vírgula, sem adicionar vírgulas
		const csvContent = `${header}\n`; // Adiciona quebra de linha após o cabeçalho

		const blob = new Blob(["\uFEFF" + csvContent], { type: "text/csv;charset=utf-8" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = "template-import-itens.csv";
		link.click();
		URL.revokeObjectURL(url);
	}

	async function handleUploadFile(uploadFile) {
		try {
			const upItems = [];
			const upCategorias = [];
			const upNivel1 = [];
			const upNivel2 = [];
			const upNivel3 = [];
			const upNivel4 = [];
			const upParceiros = [];
			let upcomingMarcas = [];

			const categoriaMap = new Map((categorias || []).map((cat) => [cat.descricao, cat]));
			const parceiroMap = new Map((parceiros || []).map((parc) => [parc.nome, parc]));
			const nivel1Map = new Map((nivel1 || []).map((niv) => [niv.descricao, niv]));
			const nivel2Map = new Map((nivel2 || []).map((niv) => [niv.descricao, niv]));
			const nivel3Map = new Map((nivel3 || []).map((niv) => [niv.descricao, niv]));
			const nivel4Map = new Map((nivel4 || []).map((niv) => [niv.descricao, niv]));
			let marcaMap = new Map((marcas || []).map((marca) => [marca.marca, marca]));

			const processedTags = new Set();

			for (let i = 1; i <= uploadFile.length; i++) {
				if (!uploadFile[i]) continue;

				const {
					tag = "",
					id_categoria,
					ean,
					id_nivel_loc_1,
					id_nivel_loc_2,
					id_nivel_loc_3,
					id_nivel_loc_4,
					inf_compl_1,
					inf_compl_2,
					inf_compl_3,
					inf_compl_4,
					inf_compl_5,
					inf_compl_6,
					inf_compl_7,
					inf_compl_8,
					inf_compl_9,
					inf_compl_10,
					id_marca,
					id_modelo,
					valor,
					id_parceiro,
					observacao,
					ativo,
					foto,
					id_externo,
					id_item_vinculado,
					up_1,
					up_2,
					up_3,
					up_4,
					up_5,
				} = uploadFile[i];

				const formatTag = tag ? String(tag).padStart(24, "0") : "0".padStart(24, "0");
				if (processedTags.has(formatTag)) continue;
				processedTags.add(formatTag);

				const getCategoria = categoriaMap.get(id_categoria);
				const getParceiro = parceiroMap.get(id_parceiro);
				const getNivel1 = nivel1Map.get(id_nivel_loc_1);
				const getNivel2 = nivel2Map.get(id_nivel_loc_2);
				const getNivel3 = nivel3Map.get(id_nivel_loc_3);
				const getNivel4 = nivel4Map.get(id_nivel_loc_4);

				const getMarca = getCategoria && id_marca ? marcaMap.get(id_marca) : "";
				const getModelo = getMarca ? getMarca?.modelo?.find((c) => c.descricao === id_modelo) : "";
				const getValor = valor
					? isNaN(valor)
						? parseFloat(valor.replace(/[^\d,]/g, "").replace(",", "."))
						: valor
					: "";

				const item = {
					id: i,
					_id: getItemID(),
					id_conta: id_conta,
					id_categoria: id_categoria ? getCategoria?._id || getID() : null,
					categoria: getCategoria?.descricao || id_categoria,
					ean: getCategoria?.ean || ean || "",
					tag: formatTag,
					status: getStatus(formatTag, id_categoria, false, getValor),
					id_nivel_loc_1: id_nivel_loc_1 ? getNivel1?._id || getID() : "",
					id_nivel_loc_2: id_nivel_loc_2 ? getNivel2?._id || getID() : "",
					id_nivel_loc_3: id_nivel_loc_3 ? getNivel3?._id || getID() : "",
					id_nivel_loc_4: id_nivel_loc_4 ? getNivel4?._id || getID() : "",
					nivel_loc_1: getNivel1?.descricao || id_nivel_loc_1,
					nivel_loc_2: getNivel2?.descricao || id_nivel_loc_2,
					nivel_loc_3: getNivel3?.descricao || id_nivel_loc_3,
					nivel_loc_4: getNivel4?.descricao || id_nivel_loc_4,
					inf_compl_1: inf_compl_1 || "",
					inf_compl_2: inf_compl_2 || "",
					inf_compl_3: inf_compl_3 || "",
					inf_compl_4: inf_compl_4 || "",
					inf_compl_5: inf_compl_5 || "",
					inf_compl_6: inf_compl_6 || "",
					inf_compl_7: inf_compl_7 || "",
					inf_compl_8: inf_compl_8 || "",
					inf_compl_9: inf_compl_9 || "",
					inf_compl_10: inf_compl_10 || "",
					id_marca: id_marca ? getMarca?._id || getID() : "",
					id_modelo: id_modelo ? getModelo?._id || getID() : "",
					marca: id_marca,
					modelo: id_modelo,
					valor: getValor || "",
					id_parceiro: id_parceiro ? getParceiro?._id || getID() : "",
					parceiro: getParceiro?.descricao || id_parceiro,
					ativo: ativo || 1,
					foto: foto || "",
					id_externo: id_externo || "",
					id_item_vinculado: id_item_vinculado || "",
					up_1: up_1 || "",
					up_2: up_2 || "",
					up_3: up_3 || "",
					up_4: up_4 || "",
					up_5: up_5 || "",
					observacao: observacao || "",
				};

				upItems.push(item);

				if (!getCategoria && id_categoria) {
					const upCat = {
						_id: item.id_categoria,
						descricao: item.categoria,
						ean: item.ean,
						id_conta: id_conta,
					};
					upCategorias.push(upCat);
					categoriaMap.set(id_categoria, upCat);
				}

				if (!getNivel1 && id_nivel_loc_1) {
					const upNivel = {
						_id: item.id_nivel_loc_1,
						descricao: item.nivel_loc_1,
						id_conta: id_conta,
					};
					upNivel1.push(upNivel);
					nivel1Map.set(id_nivel_loc_1, upNivel);
				}

				if (!getNivel2 && id_nivel_loc_2) {
					const upNivel = {
						_id: item.id_nivel_loc_2,
						descricao: item.nivel_loc_2,
						id_nivel_loc1: item.id_nivel_loc_1,
						id_conta: id_conta,
					};
					upNivel2.push(upNivel);
					nivel2Map.set(id_nivel_loc_2, upNivel);
				}

				if (!getNivel3 && id_nivel_loc_3) {
					const upNivel = {
						_id: item.id_nivel_loc_3,
						descricao: item.nivel_loc_3,
						id_nivel_loc2: item.id_nivel_loc_2,
						id_conta: id_conta,
					};
					upNivel3.push(upNivel);
					nivel3Map.set(id_nivel_loc_3, upNivel);
				}

				if (!getNivel4 && id_nivel_loc_4) {
					const upNivel = {
						_id: item.id_nivel_loc_4,
						descricao: item.nivel_loc_4,
						id_nivel_loc3: item.id_nivel_loc_3,
						id_conta: id_conta,
					};
					upNivel4.push(upNivel);
					nivel4Map.set(id_nivel_loc_4, upNivel);
				}

				if (!getMarca && id_marca) {
					const upMarca = {
						_id: item.id_marca,
						marca: item.marca,
						id_categoria: item.id_categoria,
						modelo: [],
						id_conta: id_conta,
					};
					upcomingMarcas.push(upMarca);
					marcaMap.set(id_marca, upMarca);
				}

				if (!getModelo && id_modelo) {
					const upMarca = marcaMap.get(id_marca);
					if (upMarca) {
						const newModelo = {
							_id: item.id_modelo,
							id_conta: id_conta,
							descricao: item.modelo,
						};
						upMarca.modelo.push(newModelo);
					}
				}

				if (!getParceiro && id_parceiro) {
					const upParceiro = {
						_id: item.id_parceiro,
						descricao: item.parceiro,
						id_nivel_loc3: item.id_nivel_loc_3,
						id_conta: id_conta,
					};
					upParceiros.push(upParceiro);
					parceiroMap.set(id_parceiro, upParceiro);
				}
			}

			setItems(upItems);
			setUpMarcas(upcomingMarcas);
			setUpComming({
				categoria: upCategorias,
				itens: upItems,
				nivel1: upNivel1,
				nivel2: upNivel2,
				nivel3: upNivel3,
				nivel4: upNivel4,
				parceiros: upParceiros,
			});

			setImportCount([
				{
					color: green[600],
					label: "Novos Itens",
					icon: "lets-icons:check-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Correto"))?.length,
				},
				{
					color: grey[500],
					label: "Já cadastrados",
					icon: "lets-icons:check-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Registrado"))?.length,
				},
				{
					color: red[300],
					label: "Tag repetidas",
					icon: "lets-icons:close-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Duplicada"))?.length,
				},
				{
					color: red[300],
					label: "Itens incorretos",
					icon: "lets-icons:close-ring-duotone",
					value: upItems.filter((item) => item.status.includes("Erro"))?.length,
				},
				{
					color: grey[500],
					label: "Itens importados",
					icon: "lets-icons:folder-check-duotone",
					value: upItems.filter((item) => !item.status.includes("Erro"))?.length,
				},
				{
					color: grey[500],
					label: "Total de itens",
					icon: "lets-icons:folder-up-duotone",
					value: upItems?.length,
				},
			]);
		} finally {
			setLoading(false);
		}
	}

	function formatConfig(propsModulo = {}) {
		const data = {
			infoPosition: propsModulo?.position,
			keyTag: propsModulo?.keyTag,
			upcomingFields: propsModulo?.fields,
			fixedField: propsModulo?.fixedField || [],
			XMLFields: propsModulo?.xmlUploadFields,
			countField: propsModulo?.fields?.length,
			header: propsModulo?.header || false,
		};
		return data;
	}

	return (
		<>
			<Stack spacing={3}>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h1">Importar arquivo CSV</Typography>
					<Stack spacing={1} direction="row">
						<Button
							variant="outlined"
							color="dark"
							onClick={(e) => {
								navigate("/items");
							}}
						>
							{t("actions.exit")}
						</Button>
						<Button
							variant="contained"
							onClick={(e) => {
								handleSave();
							}}
						>
							{t("actions.save")}
						</Button>
					</Stack>
				</Stack>

				<Stack spacing={3} as={Paper} sx={{ borderRadius: 3, p: 3 }}>
					<Stack direction="row" justifyContent="end" alignItems="center" spacing={1}>
						<FormControl sx={{ width: "15ch" }}>
							<InputLabel id="model-input">{t("common.model")}</InputLabel>
							<Select
								size="small"
								labelId="model-input"
								label={t("common.model")}
								onChange={(e) => {
									setConfigImport(formatConfig(e.target.value));
								}}
							>
								<MenuItem key="null" value={null}>
									--
								</MenuItem>
								{modelos.map((m, index) => (
									<MenuItem key={index} value={m}>
										{m?.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Button
							onClick={(e) => {
								handleTemplate();
							}}
						>
							Baixar {t("export.model")}
						</Button>
						<Button
							variant="outlined"
							color="dark"
							startIcon={<Icon icon="solar:settings-linear" />}
							onClick={() => {
								handleNavigate();
							}}
						>
							Configurar
						</Button>
					</Stack>

					<div>
						<Stack alignItems="flex-end">
							<FormControlLabel
								labelPlacement="start"
								control={
									<Checkbox
										checked={hasHeader}
										onChange={() => {
											setHasHeader(!hasHeader);
										}}
									/>
								}
								label={t("common.ask_header")}
							/>
						</Stack>

						<ImportCSV
							header={configImport?.header || hasHeader}
							{...configImport}
							onUpload={(e) => {
								handleFile(e);
							}}
						/>
					</div>

					{importCount ? (
						<Stack direction="row" justifyContent="center">
							<Stack
								direction="row"
								divider={<Divider orientation="vertical" flexItem />}
								spacing={1}
								sx={{ boxShadow: 1, p: 2, borderRadius: 2, border: 1, borderColor: grey[100] }}
							>
								{importCount?.map((item) => (
									<>
										<Grid2 container spacing={1} sx={{ p: 1 }}>
											<Grid2>
												<Icon width="32" icon={item.icon} color={item?.color} />
											</Grid2>
											<Grid2>
												<Typography variant="subtitle">{item.label}:</Typography>
											</Grid2>
											<Grid2>
												<Typography>{item.value}</Typography>
											</Grid2>
										</Grid2>
									</>
								))}
							</Stack>
						</Stack>
					) : (
						""
					)}

					{items ? (
						<DataGrid
							localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
							rows={items}
							columns={columns}
							editMode="row"
							disableRowSelectionOnClick={true}
							sx={{
								border: 0,
							}}
							pageSizeOptions={[10, 50, 100, 500, 1000]}
							processRowUpdate={(updatedRow, originalRow) => {
								setItems((prevRows) => {
									const newRows = [...prevRows];
									newRows[originalRow.id] = updatedRow;
									return newRows;
								});
							}}
							getRowHeight={({ id, densityFactor }) => {
								if (id % 2 === 0) {
									return 50 * densityFactor;
								}

								return null;
							}}
							slotProps={{
								columnsManagement: {
									toggleAllMode: "filteredOnly",
								},
							}}
						/>
					) : (
						""
					)}
				</Stack>
			</Stack>

			{showModal && (
				<CustomModal dividers={false}>
					<Stack spacing={2} sx={{ p: 2 }}>
						<Typography color="error">
							<Icon icon="ph:warning-fill" width="64" />
						</Typography>
						<div>
							<Typography variant="h1">{t("messages.attention")}</Typography>
							<Typography variant="subtitle">
								Não há uma configuração de importação definida. Deseja continuar mesmo assim?
							</Typography>
						</div>
						<Stack direction="row" justifyContent="flex-end" spacing={2}>
							<Button
								variant="outlined"
								color="dark"
								onClick={() => {
									navigate("/items/import/config/csv", {
										state: { xmlfields: fileHeader, preLoad: fileData },
									});
								}}
							>
								Configurar
							</Button>
							<Button
								variant="contained"
								color="dark"
								onClick={() => {
									setShowModal(false);
									handleUpload(fileData);
								}}
							>
								Continuar
							</Button>
						</Stack>
					</Stack>
				</CustomModal>
			)}

			<Loading show={loading} />

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(e);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
