import { useEffect, useState } from "react";

import {
	Box,
	Button,
	ButtonGroup,
	Container,
	FormControl,
	FormLabel,
	Grid2,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Stack,
	Typography,
} from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import { t } from "i18next";

import RegistersList from "./RegistersList";

import api from "../../services/api";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import RegistersGrid from "./RegistersGrid";
import React from "react";
import Loading from "../../components/loading";
import { useLocation } from "react-router-dom";

//--------------------------------------------------------

export default function Registers() {
	const location = useLocation();

	const { filters } = location?.state || {};

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState();

	const [displayMode, setDisplayMode] = useState("list");

	const [registrosUnfiltered, setRegistrosUnfiltered] = useState([]);

	const [registros, setRegistros] = useState([]);

	const [header, setHeader] = useState([]);

	// @ Filtros
	const [statusList, setStatusList] = useState([]);

	const [filterByStatus, setFilterByStatus] = useState(filters?.filterByStatus || "");

	const [categoriaList, setCategoriaList] = useState([]);

	const [filterByCategoria, setFilterByCategoria] = useState(filters?.filterByCategoria || "");

	const [startDate, setStartDate] = useState(filters?.startDate || null);

	const [endDate, setEndDate] = useState(filters?.endDate || new Date());

	const [dynamicFilters, setDynamicFilters] = useState(filters?.dynamicFilters || {});

	useEffect(() => {
		function getRegistros() {
			setLoading(true);
			api
				.get(`/rastreamentoItem/${sessionStorage.getItem("idConta")}/*/*`)
				.then((response) => {
					if (!response.data?.length) {
						return;
					}

					const uniqueResults = [];
					const seenIds = new Set();

					response.data.reverse().forEach((item) => {
						if (!seenIds.has(item?.id_item?._id)) {
							seenIds.add(item?.id_item?._id);
							uniqueResults.push(item);
						}
					});

					if (!uniqueResults) {
						throw "error";
					}

					// Ordenar os dados por dt_entrega (mais recente primeiro)
					const sortedData = uniqueResults.sort((a, b) => {
						const dateA = a.dt_entrega ? dayjs(new Date(a.dt_entrega)).valueOf() : 0;
						const dateB = b.dt_entrega ? dayjs(new Date(b.dt_entrega)).valueOf() : 0;
						return dateB - dateA;
					});

					const resultData = sortedData.map((data) => ({
						id_item: data?.id_item?._id,
						item: { descricao: data?.id_item?.id_categoria?.descricao, tag: data?.id_item?.tag },
						status: data?.status,
						["Última atualização"]: data?.dt_entrega
							? dayjs(new Date(data?.dt_entrega)).format("DD MMM, YYYY HH:mm")
							: "",
						imagem: data?.imagem,
						nome: data?.nome,
						...data?.campos,
					}));

					setRegistros(resultData);

					setRegistrosUnfiltered(resultData);

					const statusData = [...new Set(resultData.map((res) => res.status))];
					setStatusList(statusData);

					const categoriasData = [...new Set(resultData.map((res) => res?.item?.descricao))];
					setCategoriaList(categoriasData);

					const resultHeader = [
						...new Set(
							resultData.flatMap((res) =>
								Object.keys(res)
									.filter((key) => key !== "id_item" && key !== "imagem")
									.map((key) => key)
							)
						),
					];

					setHeader(resultHeader);

					const allFilters = {};

					resultData.forEach((item) => {
						Object.keys(item).forEach((key) => {
							if (
								key !== "id_item" &&
								key !== "Última atualização" &&
								key !== "item" &&
								key !== "status" &&
								key !== "imagem" &&
								key !== "lat" &&
								key !== "long"
							) {
								if (!allFilters[key]) allFilters[key] = new Set();
								allFilters[key].add(item[key]);
							}
						});
					});

					if (filters?.dynamicFilters) {
						return;
					}
					setDynamicFilters(
						Object.keys(allFilters).reduce((acc, key) => {
							acc[key] = "";
							return acc;
						}, {})
					);
				})
				.catch(() => {
					setMessage({
						type: "error",
						message: t("messages.errors.500"),
						title: t("messages.errors.error"),
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}

		getRegistros();
	}, []);

	useEffect(() => {
		function handleFilter() {
			if (!filterByStatus && !filterByCategoria && !startDate && !endDate && !Object.keys(dynamicFilters).length) {
				setRegistros(registrosUnfiltered);
				return;
			}

			const filteredData = registrosUnfiltered.filter((i) => {
				const matchStatus = filterByStatus ? i.status === filterByStatus : true;
				const matchCategoria = filterByCategoria ? i?.item?.descricao === filterByCategoria : true;

				// Filtragem por data
				const dtEntrega = i["Última atualização"] ? dayjs(i["Última atualização"], "DD MMM, YYYY HH:mm") : null;
				const matchDate = startDate && endDate ? dtEntrega?.isBetween(startDate, endDate, "day", "[]") : true;

				// Filtragem por campos dinâmicos
				const matchDynamicFilters = Object.keys(dynamicFilters).every((key) =>
					dynamicFilters[key] ? i[key] === dynamicFilters[key] : true
				);

				return matchStatus && matchCategoria && matchDate && matchDynamicFilters;
			});

			setRegistros(filteredData);
		}

		if (registrosUnfiltered.length) {
			handleFilter();
		}
	}, [filterByStatus, filterByCategoria, startDate, endDate, dynamicFilters, registrosUnfiltered]);

	return (
		<>
			<Container>
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between" alignItems="end">
						<Typography variant="h1">{t("common.registers")}</Typography>
					</Stack>

					<Stack as={Paper} spacing={3} sx={{ borderRadius: 5, p: 3 }}>
						<Grid2 container spacing={3}>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>Status</FormLabel>
									<Select
										size="small"
										value={filterByStatus}
										onChange={(e) => {
											setFilterByStatus(e.target.value);
										}}
									>
										<MenuItem value={null}>--</MenuItem>
										{statusList.map((status) => (
											<MenuItem value={status}>{status}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.categories")}</FormLabel>
									<Select
										size="small"
										value={filterByCategoria}
										onChange={(e) => {
											setFilterByCategoria(e.target.value);
										}}
									>
										<MenuItem value={null}>--</MenuItem>
										{categoriaList.map((categoria) => (
											<MenuItem value={categoria}>{categoria}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.date")} Inicio</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											value={startDate ? dayjs(startDate) : null}
											onChange={(newValue) => setStartDate(new Date(newValue))}
											maxDate={dayjs(endDate)}
											format="DD/MM/YYYY"
											slotProps={{ textField: { size: "small" } }}
										/>
									</LocalizationProvider>
								</FormControl>
							</Grid2>
							<Grid2 size={{ xs: 12, md: 3 }}>
								<FormControl fullWidth>
									<FormLabel>{t("common.date")} Fim</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											value={endDate ? dayjs(endDate) : null}
											onChange={(newValue) => setEndDate(new Date(newValue))}
											endDate={dayjs(startDate)}
											format="DD/MM/YYYY"
											slotProps={{ textField: { size: "small" } }}
										/>
									</LocalizationProvider>
								</FormControl>
							</Grid2>

							{Object.keys(dynamicFilters).map((key) => (
								<Grid2 size={{ xs: 12, md: 3 }} key={key}>
									<FormControl fullWidth>
										<FormLabel sx={{ textTransform: "capitalize" }}>{key.replace(/_/g, " ")}</FormLabel>
										<Select
											size="small"
											value={dynamicFilters[key]}
											onChange={(e) => setDynamicFilters({ ...dynamicFilters, [key]: e.target.value })}
										>
											<MenuItem value="">--</MenuItem>
											{[...new Set(registrosUnfiltered.map((item) => item[key]))].map((value) => (
												<MenuItem key={value} value={value}>
													{value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid2>
							))}
						</Grid2>
						<Stack direction="row" justifyContent="space-between" alignItems="end">
							<Box sx={{ borderRadius: 1, backgroundColor: "grey.200", p: 1 }}>
								<Typography variant="subtitle" sx={{ fontWeight: 600 }}>
									{registros?.length} itens
								</Typography>
							</Box>
							<Stack direction="row" spacing={0.5} sx={{ p: 0.5, borderRadius: 1, border: 1, borderColor: "divider" }}>
								<IconButton
									size="small"
									onClick={() => {
										setDisplayMode("list");
									}}
									sx={{ borderRadius: 1, backgroundColor: displayMode == "list" ? "divider" : "" }}
								>
									<Icon icon="solar:list-bold" />
								</IconButton>
								<IconButton
									size="small"
									onClick={() => {
										setDisplayMode("grid");
									}}
									sx={{ borderRadius: 1, backgroundColor: displayMode == "grid" ? "divider" : "" }}
								>
									<Icon icon="mdi:dots-grid" />
								</IconButton>
							</Stack>
						</Stack>
						{registros.length ? (
							displayMode == "list" ? (
								<>
									<RegistersList
										rows={registros}
										header={header}
										filters={{ filterByCategoria, filterByStatus, startDate, endDate, dynamicFilters }}
									/>
								</>
							) : displayMode == "grid" ? (
								<>
									<RegistersGrid
										rows={registros}
										header={header}
										filters={{ filterByCategoria, filterByStatus, startDate, endDate, dynamicFilters }}
									/>
								</>
							) : (
								""
							)
						) : null}
					</Stack>
				</Stack>
			</Container>

			<Loading show={loading} />
		</>
	);
}
