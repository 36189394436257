import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// componentes
import api from "../../services/api";
import LoadingPage from "../../components/loading/LoadingPage";

// estilos
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./style.css";
import logoInova from "../../assets/logoinova.png";
import stock from "../../assets/stock.gif";

//packages
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

// ----------------------------------------------------------------

export default function Register() {
	const { t } = useTranslation();

	const history = useNavigate();

	const [nomeRegister, setNomeRegister] = useState("");

	const [emailRegister, setEmailRegister] = useState("");

	const [passRegister, setPassRegister] = useState("");

	const [rpassRegister, setRPassRegister] = useState("");

	const [loading, setLoading] = useState(false);

	const [passError, setPassError] = useState("");

	useEffect(() => {
		const error = verifySenha();
		if (error) {
			setPassError(t(`login.messages.sign_up.errors.password.${error}`));
		} else {
			setPassError("");
		}
	}, [passRegister, rpassRegister]);

	function verifySenha() {
		let error;

		// Definindo as regras de validação da senha
		const minLength = 6;
		const hasUpperCase = /[A-Z]/.test(passRegister);
		const hasLowerCase = /[a-z]/.test(passRegister);
		const hasNumber = /\d/.test(passRegister);
		const hasSpecialChar = /[?:*\/]/.test(passRegister);
		const isCommonPassword = !["senha", "123456", "abcdef"].includes(passRegister.toLowerCase());
		const isNotUsername = passRegister.toLowerCase() !== nomeRegister.toLowerCase();

		if (passRegister === "" || rpassRegister === "") {
			return error;
		}

		if (passRegister.length < minLength) {
			error = "minLength";
			return error;
		}

		if (!hasUpperCase || !hasLowerCase || !hasNumber) {
			error = "rules";
			return error;
		}

		if (hasSpecialChar) {
			error = "hasSpecialChar";
			return error;
		}

		if (!isCommonPassword) {
			error = "isCommonPassword";
			return error;
		}

		if (!isNotUsername) {
			error = "notUsername";
			return error;
		}

		if (passRegister !== rpassRegister) {
			error = "matchPassword";
			return error;
		}

		return error;
	}

	function Reg(e) {
		e.preventDefault();
		Swal.fire({
			title: `${t("login.messages.sign_up.warning")}`,
			text: `${t("login.messages.sign_up.text")}`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#DD6B55",
			confirmButtonText: `${t("login.messages.sign_up.yes")}`,
			cancelButtonText: `${t("login.messages.sign_up.no")}`,
		}).then((result) => {
			if (result.isConfirmed) {
				if (nomeRegister === "") {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.name_missing")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (emailRegister === "") {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.email_missing")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (!(emailRegister.search("@") >= 0)) {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.email_error")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (passError) {
					Swal.fire({
						title: "Oops... !",
						text: `${passError}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (passRegister !== rpassRegister || passRegister === "" || rpassRegister === "") {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.pass_match_error")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (passRegister.length < 6) {
					Swal.fire({
						title: "Oops... !",
						text: `${t("login.messages.sign_up.errors.pass_size_error")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else if (
					passRegister.includes("?") ||
					passRegister.includes(":") ||
					passRegister.includes("*") ||
					passRegister.includes("/")
				) {
					Swal.fire({
						title: "Oops... !",
						html: `${t("login.messages.sign_up.errors.pass_char_error")}`,
						icon: "error",
						showConfirmButton: false,
						timerProgressBar: true,
						timer: "2500",
					});
				} else {
					let dt = new Date();
					let dt_reg =
						dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2);
					dt_reg +=
						" " +
						("0" + dt.getHours()).slice(-2) +
						":" +
						("0" + dt.getMinutes()).slice(-2) +
						":" +
						("0" + dt.getSeconds()).slice(-2);

					let reg = [];
					reg.push({
						id_conta: "0",
						ativo: 1,
						uuid: "",
						nome: nomeRegister,
						email: emailRegister,
						endereco: "",
						cnpj: "",
						observacao: "",
						foto: "",
						dt_registro: dt_reg,
						dt_licenca: dt_reg,
					});

					let options = {
						headers: {
							"Content-Type": ["application/json"],
						},
					};

					setLoading(true);
					api.post("/conta", reg, options).then(
						async function (res) {
							reg = [];
							reg.push({
								id_usuario: "0",
								id_conta: res.data[0]._id,
								ativo: 1,
								perfil: "admin",
								nome: nomeRegister,
								login: emailRegister,
								senha: passRegister,
								foto: "",
								dt_acesso: dt_reg,
							});

							api.post("/usuario", reg, options).then(
								(response) => {
									Swal.fire({
										title: `${t("login.messages.sign_up.success")}`,
										text: `${t("login.messages.sign_up.success_text")}`,
										icon: "success",
										showConfirmButton: false,
										timerProgressBar: true,
										timer: "2500",
									}).then((result) => {
										sessionStorage.setItem("idUser", response.data[0]._id);
										sessionStorage.setItem("idConta", response.data[0].id_conta);
										sessionStorage.setItem("perfil", "admin");
										history("/items");
									});
								},
								(response) => {
									Swal.fire({
										title: "Oops... !",
										text: `${t("login.messages.sign_up.errors.error")}`,
										icon: "error",
										showConfirmButton: false,
										timerProgressBar: true,
										timer: "2500",
									});
									setLoading(false);
								}
							);
						},
						(error) => {
							const errorMessage = error.response?.data?.error;
							Swal.fire({
								title: "Oops... !",
								text: `${errorMessage || t("login.messages.sign_up.errors.error")}`,
								icon: "error",
								showConfirmButton: false,
								timerProgressBar: true,
								timer: "2500",
							});
							setLoading(false);
						}
					);
				}
			}
		});
	}

	return (
		<>
			{loading ? <LoadingPage /> : ""}
			<Container fluid className="bg-white">
				<Row className="justify-content-md-center align-items-center vh-100">
					<Col sm={3} className="d-flex flex-column justify-content-md-center sm-noborder rounded px-3 py-3">
						<div className="h-100 d-flex py-3">
							<img src={logoInova} alt="Logo InovaOne" style={{ width: "190px", height: "75px" }} className="mx-auto" />
						</div>
						<Form>
							<h3 className="py-3">{t("login.sign_up")}</h3>
							<Form.Group className="mb-3 text-secondary" controlId="formGroupEmail">
								<Form.Label>{t("login.placeholder.name")}</Form.Label>
								<Form.Control type="text" value={nomeRegister} onChange={(e) => setNomeRegister(e.target.value)} />
							</Form.Group>
							<Form.Group className="mb-3 text-secondary" controlId="formGroupEmail">
								<Form.Label>{t("login.placeholder.email")}</Form.Label>
								<Form.Control type="text" value={emailRegister} onChange={(e) => setEmailRegister(e.target.value)} />
							</Form.Group>
							<Row className="mb-3 text-secondary">
								<Col>
									<Form.Label>{t("login.placeholder.password")}</Form.Label>
									<Form.Control
										type="password"
										value={passRegister}
										onChange={(e) => setPassRegister(e.target.value)}
									/>
								</Col>
								<Col>
									<Form.Label>{t("login.placeholder.confirm_password")}</Form.Label>
									<Form.Control
										type="password"
										value={rpassRegister}
										onChange={(e) => setRPassRegister(e.target.value)}
									/>
								</Col>
								<p className="text-danger mb-0 pb-0">{passError}</p>
							</Row>
							<div className="d-grid gap-2">
								<Button variant="light" type={"submit"} onClick={Reg} value={"CADASTRAR"}>
									{t("login.buttons.register")}
								</Button>

								<div onClick={(e) => history("/login")} className="py-3 text-secondary">
									{t("login.register_text")}
									<span className="fw-bold px-1" style={{ color: "#4362E7", cursor: "pointer" }}>
										{t("login.sign_in")}
									</span>
								</div>
							</div>
						</Form>
					</Col>
					<Col sm={5}>
						<div className="h-100 w-100 d-flex flex-column px-5">
							<img src={stock} alt="Imagem de verificação de estoque" className="mx-auto w-100 h-100 fluid register" />
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}
